export interface ClusterCreationStep {
  onNextStep?: () => void;
  onPreviousStep?: () => void;
}

export const clusterCreationSteps : ClusterCreationStep[] = [
  {
    step: 1,
    key: "PROVIDERS_CONNFIGURATION_STEP",
    label: "Cluster Configuration",
  },
  {
    step: 2,
    key: "SERVICES_IMPORT_STEP",
    label: "Choosing foundations",
  }
];

export interface ClusterCreationStep {
  step: number,
  key: string,
  label: string
}


