import { Button, ButtonLabel } from "components/sredx-ui/Button";
import styles from "./GitopsAccountsPage.module.css";
import { PageContent } from "components/sredx-ui/PageContent";
import { Heading } from "components/sredx-ui/Text";
import { GitopsAccountsListingContainer } from "containers/gitops/GitopsAccountsListingContainer/GitopsAccountsListingContainer";
import { useModal } from "hooks/useModal";
import { GitopsAccountCreateContainer } from "containers/gitops/GitopsAccountCreateContainer/GitopsAccountCreateContainer";

const GitopsAccountsPage = () => {
  const { openModal, closeModal } = useModal();

  const onAddGitopsAccountClick = () =>{
    openModal({
      content: <GitopsAccountCreateContainer onCancel={closeModal} />
    });
  }

  return (
    <PageContent>
        <div className={styles.header_wrapper}>
          <div>
            <div>
            <Heading>GitOps Accounts</Heading>
            <p>
              Manage all your GitOps accounts in one place.
            </p>
            </div>
          </div>
          <div>
            <Button onClick={onAddGitopsAccountClick}>
              <ButtonLabel >Add GitOps Account</ButtonLabel>
            </Button>
          </div>
        </div>
        <GitopsAccountsListingContainer onAddGitopsAccountClick={onAddGitopsAccountClick} />
    </PageContent>
  );
};

export default GitopsAccountsPage;
