import { CloudProvider } from "types/csp/providers";
import styles from "./ClusterCard.module.css";
import { CDTool, GitProvider } from "types/gitops/gitops";
import { ResourceType, Cluster, StatusType } from "types/clusters/clusters";
import { resolveToolUrl } from "../utils";

export const resolveLogoText = (
  service: CloudProvider | CDTool | ResourceType | GitProvider,
  includeText: boolean = true
) => {


  const ImgComponent  = resolveToolUrl(service);

  if (ImgComponent) {

    if (includeText) {
      return (
        <div style={{ display: "flex", alignItems: "center", gap: "4px"}}>
          <ImgComponent className={styles.image}/>
          {service}
        </div>
      );
    } else {
      return  <ImgComponent/>;
    }
  }

  return service;
};

export const isKubeConfigDownloadEnabled = (cluster: Cluster) => {
  if(cluster.status.phase === 'READY') {
    return true;
  }
  return false;
};


export function mapClusterPhaseToStatus(phase: StatusType) {
  switch(phase) {
    case 'RUNNING': 
      return 'success';
    case 'PENDING':
      return 'warning';
    case 'DELETING':
      return 'warning';
    case 'ERROR':
      return 'danger';
    default:
      return 'unknown';
  }
}
