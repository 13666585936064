
import { useGetControlplaneKubeConfig } from "./useGetKubeConfig";
import { triggerDownload } from "utils/download";

export const useControlplaneKubeConfig = () => {
  const { isFetching, isLoading, data, isSuccess, isError, error } = useGetControlplaneKubeConfig();
  
  const handleDownload = () => {
    if (isSuccess ) {
      const decodedKubeConfig = data?.kubeconfig || "";
      triggerDownload(decodedKubeConfig, `kubeconfig.yaml`);
    }
    if (isError) {
      console.error("Error while downloading kubeConfig: ", error);
    }
  };

  const isClusterDownloading = () => {
    return isFetching || isLoading;
  };

  return {
    isClusterDownloading,
    handleDownload,
  };
};
