
import styles from "./FoundationTemplatesDatatable.module.css";
import { Table, TableBody, TableCell, TableHeader } from "components/sredx-ui/Table";
import { TableHead } from "components/sredx-ui/Table/TableHead/TableHead";
import { TableRow } from "components/sredx-ui/Table/TableRow/TableRow";
import { CloudIcon } from "components/Layout/Icon";
import { ProgressBar } from "components/sredx-ui/ProgressBar";
import { FoundationTemplatesDto } from "types/foundationtemplates/foundationtemplates";
import { resolveToolUrl } from "components/clusters/utils";
import { TrashIcon } from "@heroicons/react/outline";

const CSP_ACCOUNT_TABLE_HEADER = [
  "Name",
  "Chart",
  "Repository Url",
  "Version",
  "STATUS",
  "Actions"
];

interface FoundationTemplatesDatatableProps {
  isFetching?: boolean;
  foundationTemplates?: FoundationTemplatesDto[];
  onClickService: (serviceId: string) => void;
  onDelete?: (foundationTemplate: FoundationTemplatesDto) => void;
}

export const FoundationTemplatesDatatable = ({
  foundationTemplates = [],
  onClickService,
  isFetching,
  onDelete,
}: FoundationTemplatesDatatableProps) => {

  return (
    <Table>
      <TableHeader>
        {CSP_ACCOUNT_TABLE_HEADER.map((header) => (
          <TableHead key={header}>{header}</TableHead>
        ))}
      </TableHeader>
      <TableBody>
        {isFetching && <ProgressBar position={"absolute"} />}
        {foundationTemplates?.map((foundationTemplate) => {
        const ToolImg = resolveToolUrl(foundationTemplate.chart);
        return (
          <TableRow
            className={styles.row_wrapper}
            key={foundationTemplate.name}
            onClick={() => onClickService(foundationTemplate.name)}
          >
            <TableCell className={styles.first_cell}>
              <div className={styles.service_name_wrapper}>
                <div className={styles.service_icon}>
                  {ToolImg ? <ToolImg toolName={foundationTemplate.chart} className="h-6" />: <CloudIcon />}
                </div>
                <span className={styles.service_name}>{foundationTemplate.name}</span>
              </div>
            </TableCell>
            <TableCell>
              <div className={styles.service_name_wrapper}>
                <span className={styles.service_name}>{foundationTemplate.chart}</span>
              </div>
            </TableCell>
            <TableCell >
              <div className={styles.service_name_wrapper}>
                <span className={styles.service_name}>{foundationTemplate.repoUrl}</span>
              </div>
            </TableCell>
            <TableCell >
              <div className={styles.service_name_wrapper}>
                <span className={styles.service_name}>{foundationTemplate.version}</span>
              </div>
            </TableCell>
            <TableCell>
              <div className={styles.service_name_wrapper}>
                <span className={styles.service_name}>{foundationTemplate.phase == "READY" ? "STABLE": foundationTemplate.phase}</span>
              </div>
            </TableCell>
            <TableCell>
              <div className={styles.service_name_wrapper}>
                <span className={styles.service_name}>
                {onDelete && (
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      onDelete(foundationTemplate);
                    }}
                  >
                    <TrashIcon className="mt-1 h-5 text-slate-900 hover:bg-slate-300 rounded" />
                  </button>
                )}
                </span>
              </div>
            </TableCell>
          </TableRow>
        )})}
      </TableBody>
    </Table>
  );
};
