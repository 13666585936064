// import ClusterDetailsLoadingContainer from "containers/clusters/ClusterCreationLoadingContainer/ClusterCreationLoadingContainer";
import styles from "./ClusterDetailsPage.module.css";
import { PageContent } from "components/sredx-ui/PageContent";
import { ClusterDetailsContainer } from "containers/clusters/ClusterDetailsContainer";
import { useParams } from "react-router-dom";
import { useGetClusterDetailInfo } from "services/clusters/useGetClusterDetailInfo";

export const ClusterDetailsPage = () => {
  const { clusterId } = useParams<{ clusterId: string }>();
  const { data: cluster, isLoading, isError } = useGetClusterDetailInfo(clusterId);

  if(isLoading){
    return <div>Cluster loading ...</div>
  }
  if(!cluster){
    return <div>Cluster is undefined ...</div>
  }
  if(isError){
    return <div>Cluster error ...</div>
  }
  // if(cluster?.status?.phase?.toLowerCase() == "creating"){
  //   return <ClusterDetailsLoadingContainer cluster={cluster} />
  // }

  return (
    <PageContent className={styles.wrapper}>
      <ClusterDetailsContainer cluster={cluster} />
    </PageContent>
  );
};
