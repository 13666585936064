import { ExclamationCircleIcon } from '@heroicons/react/outline';
import clsx from 'clsx';
import styles from './StatusIndicator.module.css'
import { lowerCase } from 'lodash';



interface StatusIndicatorProps {
    /**
     * The current status of the item, which determines the visual representation.
     */
    status: 'success' | 'warning' | 'danger' | 'unknown' | 'creating' | 'ready' | 'error' 
    label?: string
    shouldPulse?: boolean;
}


const statusStyles = {
    success: { class: styles.green, label: "success" },
    danger: { class: styles.red, label: "danger" },
    warning: { class: styles.yellow, label: "warning" },
    error: { class: styles.red, label: "error" },
    creating: { class: styles.blue, label: "creating" },
    ready: { class: styles.green, label: "ready" },
    unknown: { class: styles.gray, label: "unknown" }
};

const StatusIndicator = ({ status, label, shouldPulse = true }: StatusIndicatorProps) => {
    const { class: statusClass } = statusStyles[status]
    const applyPulse = shouldPulse && status !== 'unknown';
    const statusLowercase = lowerCase(status)
    return (
        <div className={styles.statusLowercase}>
          {statusLowercase === "error" ? (
            <ExclamationCircleIcon className={styles.errorIcon} />
          ) : (
            <div className={clsx(styles.statusDot, statusClass, applyPulse && "after:animate-ping")} />          )}
          {label}
        </div>
    );
}

export default StatusIndicator