
import { FeatureCard } from "components/home/FeatureCard/FeatureCard";
import { FeatureCardDto } from "components/home/FeatureCard/types";
import { useNavigate } from "react-router-dom";

export const HomeContentContainer = () => {
    const features: FeatureCardDto[] = [
        {
        icon: "/Growth/L4.svg",
        featureName: "Clusters",
        featureDescription: "Manage all your clusters in one place. From here, you can view, configure, and monitor the health of all clusters associated with your project. Our platform simplifies the lifecycle management of clusters, from initial creation to ongoing scaling and updates, ensuring your infrastructure grows with your needs.",
        redirectUrl: "/clusters"
        },
        {
        icon: "/Speed/L4.svg",
        featureName: "Foundation Templates",
        featureDescription: "Leverage our pre-existing foundation templates for deploying applications to your clusters. These templates include essential configurations and best practices for setting up the technology stacks, saving time while ensuring consistency across your deployments. Simply choose a template, customize it as needed, and add it to your clusters to get started.",
        redirectUrl: "/foundation-templates"
    },
      {
        icon: "/ToolsIcons/kustomize.png",
        featureName: "Gitops Accounts",
        featureDescription: "The GitOps account is at the core of our deployment architecture. It follows the D4 architecture model, ensuring a streamlined, automated process for managing and syncing the infrastructure and application states of the generated clusters. It serves as the operational hub, linking cluster states directly to the defined version in the repository.",
        redirectUrl: "/gitops"
    },
      {
        icon: "/SuperStar/L4.svg",
        featureName: "Providers Accounts",
        featureDescription: "Our system supports multiple cloud providers, including Scaleway, AWS... The provider account section allows you to integrate your cloud provider of choice, manage resources, and provision clusters across diverse platforms. Whether you're using AWS for enterprise-scale deployments or Scaleway for cost-effective solutions, we have the flexibility to support your infrastructure needs.",
        redirectUrl: "/csp"
    }
    ]
    const navigate = useNavigate();
    const onClick = (feature: FeatureCardDto) =>{
        navigate(feature.redirectUrl)
    }

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-10 mt-5">
        {
            features.map((feature)=> <FeatureCard redirect={feature}  onClick={onClick}/>)
        }
    </div>
  );
  
};
