import ControlplanesPage from "pages/Controlplanes/ControlplanesPage";
import {
  CLUSTERS_PAGE,
  CLUSTER_CREATE_PAGE,
  CLUSTER_DETAILS_PAGE,
  CONTROLPLANS_DETAILS_PAGES,
  CONTROLPLANS_PAGES,
  CSP_ACCOUNTS_PAGE,
  DATABASES_PAGE,
  FOUNDATION_TEMPLATES_PAGE,
  GITOPS_ACCOUNTS_PAGE,
  HOME_PAGE,
  LANDING_PAGE,
  SETTINGS_PAGE,
  STACKS_PAGE
} from "./paths";
import { ROUTE } from "./types";
import { AppLayout } from "components/Layout";
import { NotFoundPage } from "pages/404";
import CspAccountsPage from "pages/CspAccounts/CspAccountsPage";
import GitopsAccountsPage from "pages/GitopsAccounts/GitopsAccountsPage";
import ClustersPage from "pages/clusters/ClustersPage";
import FoundationsTemplatesPage from "pages/foundationTemplates/FoundationTemplatesPage";
import HomePage from "pages/home/HomePage";
import { LandingPage } from "pages/landing/LandingPage";
import StacksPage from "pages/stacks/StacksPage";
import SettingsPage from "pages/Settings/SettingsPage";
import DatabasesPage from "pages/Databases/DatabasesPage";
import { ClusterDetailsPage } from "pages/clusters/ClusterDetailsPage";
import { ControlplaneDetailsPage } from "pages/Controlplanes/ControlplanesDetailsPage/ControlplanesDetailsPage";
import ClusterCreatePage from "pages/clusters/ClusterCreatePage/ClusterCreatePage";

export const ROUTES: ROUTE[] = [
  {
    path: LANDING_PAGE,
    element: <LandingPage />,
  },
  {
    element: <AppLayout />,
    crumb: "Home",
    path: HOME_PAGE,
    isPrivate: true,
    children: [
      {
        path: HOME_PAGE,
        element: <HomePage />,
      }
    ]
  },
  {
    path: CLUSTERS_PAGE,
    crumb: "Clusters",
    element: <AppLayout />,
    isPrivate: true,
    children: [
      {
        path: CLUSTERS_PAGE,
        crumb: "Clusters",
        element: <ClustersPage />,
      }
    ]
  },
  {
    path: CLUSTER_CREATE_PAGE,
    crumb: "",
    element: <AppLayout />,
    isPrivate: true,
    children: [
      {
        path: CLUSTER_CREATE_PAGE,
        crumb: "Create Page",
        element: <ClusterCreatePage />,
      }
    ]
  },
  {
    path: CLUSTER_DETAILS_PAGE,
    crumb: "Clusters",
    element: <AppLayout />,
    isPrivate: true,
    children: [
      {
        path: CLUSTER_DETAILS_PAGE,
        crumb: "Clusters",
        element: <ClusterDetailsPage />,
      }
    ]
  },
  {
    path: CSP_ACCOUNTS_PAGE,
    crumb: "CSP Accounts",
    element: <AppLayout />,
    isPrivate: true,
    children: [
      {
        path: CSP_ACCOUNTS_PAGE,
        crumb: "CSP Accounts",
        element: <CspAccountsPage />,
      }
    ]
  },
  {
    path: GITOPS_ACCOUNTS_PAGE,
    crumb: "GitOps Accounts",
    element: <AppLayout />,
    isPrivate: true,
    children: [
      {
        path: GITOPS_ACCOUNTS_PAGE,
        crumb: "GitOps Accounts",
        element: <GitopsAccountsPage />,
      }
    ]
  },
  {
    path: FOUNDATION_TEMPLATES_PAGE,
    crumb: "Foundations",
    element: <AppLayout />,
    isPrivate: true,
    children: [
      {
        path: FOUNDATION_TEMPLATES_PAGE,
        crumb: "Foundations",
        element: <FoundationsTemplatesPage />,
      }
    ]
  },
  {
    path: CONTROLPLANS_PAGES,
    crumb: "Controlplanes",
    element: <AppLayout />,
    isPrivate: true,
    children: [
      {
        path: CONTROLPLANS_PAGES,
        crumb: "Controlplanes",
        element: <ControlplanesPage />,
      }
    ]
  },
  {
    path: CONTROLPLANS_PAGES,
    crumb: "Controlplanes",
    element: <AppLayout />,
    isPrivate: true,
    children: [
      {
        path: CONTROLPLANS_DETAILS_PAGES,
        crumb: "Controlplanes",
        element: <ControlplaneDetailsPage />,
      }
    ]
  },
  {
    path: STACKS_PAGE,
    crumb: "Stacks",
    element: <AppLayout />,
    isPrivate: true,
    children: [
      {
        path: STACKS_PAGE,
        crumb: "Stacks",
        element: <StacksPage />,
      }
    ]
  },
  {
    path: SETTINGS_PAGE,
    crumb: "Settings",
    element: <AppLayout />,
    isPrivate: true,
    children: [
      {
        path: SETTINGS_PAGE,
        crumb: "Settings",
        element: <SettingsPage />,
      }
    ]
  },
  {
    path: DATABASES_PAGE,
    crumb: "Databases",
    element: <AppLayout />,
    isPrivate: true,
    children: [
      {
        path: DATABASES_PAGE,
        crumb: "Databases",
        element: <DatabasesPage />,
      }
    ]
  },
  {
    path: "*",
    element: <NotFoundPage />,
  },
]