import { forwardRef } from "react";
import { IconProps } from "./types";

export const BellIcon = forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", title, ...props }, ref) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        {...props}
        ref={ref}
      >
        <title>{title}</title>
        <path
          fill={color}
          d="M11.47 3v1.139l-.302.066-.43.137-.415.171-.399.208-.38.241-.356.274-.332.302-.302.334-.276.356-.24.378-.207.399-.173.414-.136.429-.097.438-.058.447-.019.449-.02.783-.058.782-.1.78-.14.77-.177.765-.217.754-.253.74-.29.73-.328.712-.364.694-.399.674-.433.656-.464.632-.07.125-.035.138.004.141.046.14.08.118.11.09.13.059.143.02h5.073l.129.312.167.302.201.281.232.26.26.231.28.202.303.165.322.134.332.097.342.056.346.02.346-.02.342-.056.334-.097.32-.134.302-.165.284-.202.257-.231.232-.26.201-.281.167-.302.129-.312h5.073l.143-.02.13-.059.111-.09.08-.119.045-.139.004-.14-.034-.14-.073-.124-.463-.632-.432-.656-.399-.674-.362-.694-.33-.713-.29-.728-.253-.74-.218-.755-.179-.765-.136-.77-.101-.78-.058-.782-.02-.783-.02-.449-.059-.447-.097-.438-.134-.429-.173-.414-.208-.399-.241-.378-.274-.356-.304-.334-.33-.302-.358-.274-.38-.241-.399-.208-.414-.17-.427-.138-.304-.066V3h-2.06Zm1.03 2.06.405.02.4.061.392.099.38.135.365.173.348.207.326.241.3.272.27.3.241.324.21.346.17.366.137.383.099.39.058.4.02.405.018.756.053.757.086.753.12.746.156.743.191.732.223.725.256.712.288.7.32.687.352.672.38.654.413.636H5.523l.41-.636.383-.654.352-.672.32-.687.288-.7.258-.712.221-.725.19-.732.156-.743.123-.746.084-.753.055-.757.016-.756.02-.405.06-.4.097-.39.137-.383.17-.366.21-.346.242-.324.271-.3.3-.272.324-.241.346-.207.366-.173.383-.135.39-.099.4-.06.405-.02Zm-1.78 14.425h3.56l-.02.04-.16.23-.193.207-.22.177-.24.145-.257.112-.27.077-.28.038h-.28l-.279-.038-.27-.077-.259-.112-.24-.145-.217-.177-.193-.207-.163-.23-.02-.04Z"
        />
      </svg>
    );
  }
);
