import { StacksDto } from "types/stacks/stacks";

interface StackDescriptionDetailsModalProps {
  stack: StacksDto;
  display: boolean
}

export const StackDescriptionDetailsModal = ({
  stack,
  display,
}: StackDescriptionDetailsModalProps) => {

  return (
    <div className={`${display ? "" : "hidden"}`}>
      <div className="grid grid-cols-2 gap-4">
          <h3 className="Semibold">Name :</h3>
          <span className="text-slate-700 ml-5">{stack.name}</span>
        </div>
        <div className="grid grid-cols-2 gap-4">
          <h3 className="Semibold">Description :</h3>
          <span className="text-slate-700 ml-5">{stack.description}</span>
        </div>
        </div>
  );
};
