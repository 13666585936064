import { useState } from "react";
import { useFormContext } from "react-hook-form";
import { nodesizeEnum } from "types/clusters/clusters";

interface ClusterSelectNodesCountProps {
  errors?: any;
}

export const ClusterSelectNodesCount = ({
  errors,
}: ClusterSelectNodesCountProps) => {

  const [selected, setSelected] = useState<number>();
  const methods = useFormContext();
  const handleSelect = (value: number) => {
    setSelected(value);
    methods.setValue("nodesize", value);
    return;
  };
  
  return (
    <div className="flex flex-col gap-1 mt-2 mb-7">
      <div className="text-lg mb-2">
        Nodes count
      </div>
      <div className="grid grid-cols-4 lg:grid-cols-8 gap-2">
        {nodesizeEnum.map((item, i) => 
              <div
                className={`border border-gray-400 text-gray-700 justify-center items-center text-center flex p-2 gap-4 cursor-pointer w-full ${
                  selected == item && " border-cyan-600 bg-cyan-50 text-cyan-700"
                }`}
                onClick={() => handleSelect(item)}
              >
                <div>{item}</div>                
              </div>
        )}
      </div>
      <span className="text-xs text-red-800">
        {errors?.version?.message}
      </span>
      
    </div>
  );
};