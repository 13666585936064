import styles from "./DatabasesPage.module.css";
import { PageContent } from "components/sredx-ui/PageContent";
import { Heading } from "components/sredx-ui/Text";

const DatabasesPage = () => {

  return (
    <PageContent>
        <div className={styles.header_wrapper}>
          <div>
            <div>
            <Heading>Databases</Heading>
            <p>
              Self provision your databases (coming soon !!).
            </p>
            </div>
          </div>
        </div>
    </PageContent>
  );
};

export default DatabasesPage;
