import { useParams } from "react-router-dom";
import styles from "./ControlplanesDetailsPage.module.css";
import { PageContent } from "components/sredx-ui/PageContent";
import { AppReturnButton } from "components/Layout";
import ControlplaneDetailsInfoFetchContainer from "containers/controlplanes/ControlplanesDetailsFetchContainer/ControlplanesDetailsInfoFetchContainer";
// import ControlplaneManagedResourcesContainer from "containers/controlplanes/ControlplanesManagedResourcesContainer/ControlplanesManagedResourcesContainer";
import { CONTROLPLANS_PAGES } from "routes/paths";
import { ControlplaneDetailsDeleteContainer } from "containers/controlplanes/ControlplanesDetailsContainer/ControlplanesDetailsDeleteContainer/ControlplanesDetailsDeleteContainer";

export const ControlplaneDetailsPage = () => {
  const { controlplaneName } = useParams<{ controlplaneName: string }>();
  return (
    <PageContent className={styles.wrapper}>
      <AppReturnButton
        to={CONTROLPLANS_PAGES}
        label={"Back to controlplanes"}
      />

      <div className="flex flex-col gap-6">
        <ControlplaneDetailsInfoFetchContainer clusterId={controlplaneName ?? ""} />
        {/* <ControlplaneManagedResourcesContainer name={controlplaneName ?? ""} /> */}
        <ControlplaneDetailsDeleteContainer id={controlplaneName ?? ""} />
      </div>
    </PageContent>
  );
};
