import { FormField } from "components/basic/FormField";
import { TextInput } from "components/sredx-ui/Forms/TextInput";
import { ClusterSelectKubernetesVersion } from "./inputs/ClusterSelectKubernetesVersion";
import { ClusterSelectProviderAccount } from "./inputs/ClusterSelectProviderAccount";
import { ClusterSelectType } from "./inputs/ClusterSelectType";
import { ClusterSelectNodeConfig } from "./inputs/ClusterSelectNodeConfig";
import { ClusterSelectGitopsAccount } from "./inputs/ClusterSelectGitopsAccount";
import { ClusterSelectGitopsTool } from "./inputs/ClusterSelectGitopsTool";
import { ClusterSelectNodesCount } from "./inputs/ClusterSelectNodesCount";

interface ClusterCreateNewFormProps {
  errors?: any;
  cloudAccounts: { name: string; provider: string }[];
  gitopsAccounts: { name: string; owner: string }[];
  display: boolean;
  selectedAccount: string;
}
export const ClusterCreateNewForm = ({
  errors,
  cloudAccounts,
  gitopsAccounts,
  display,
  selectedAccount,
}: ClusterCreateNewFormProps) => {
  let provider = cloudAccounts.find(account => account.name === selectedAccount)?.provider;
  return (
    <div className={`flex flex-col mt-10 ${display ? "" : "hidden"}`}>
      <div className="text-lg ">
        Cluster General Informations
      </div>
      <div className="mb-5">
      <div className="grid lg:grid-cols-2 grid-cols-1 w-full justify-between gap-7 my-2">
      <FormField
        id="name"
        name="name"
        label="Cluster name"
        placeholder="Enter Cluster name"
        component={TextInput}
        error={errors?.name?.message}
      />
       <FormField
        id="repoName"
        name="repoName"
        label="Gitops Gitops Repository Name"
        placeholder="Enter a gitops repository Name"
        component={TextInput}
        error={errors?.repoName?.message}
      />
      </div>
      </div>
      
      <ClusterSelectKubernetesVersion errors={errors} />
      <ClusterSelectNodesCount errors={errors} />
      <ClusterSelectProviderAccount cloudAccounts={cloudAccounts} errors={errors} />
      <ClusterSelectType provider={provider} errors={errors} />
      <ClusterSelectNodeConfig provider={provider} errors={errors} />
      
      <ClusterSelectGitopsAccount gitopsAccounts={gitopsAccounts} errors={errors} />
      <ClusterSelectGitopsTool errors={errors} />
    </div>
  );
};