import { forwardRef } from "react";
import { IconProps } from "./types";

export const HelmIcon = forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, ref) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 304 351"
        {...props}
        ref={ref}
      >
        <title>Helm</title>
        <mask id="a" fill="#fff">
          <path d="M0 0h313.3v159.9H0z" fill-rule="evenodd" />
        </mask>
        <mask id="b" fill="#fff">
          <path d="M0 0h313.3v159.9H0z" fill-rule="evenodd" />
        </mask>
        <g fill="none" fill-rule="evenodd">
          <path
            d="M.7 138h19.8v26.8h23.9V138h19.8v75.3H44.4v-28.7h-24v28.7H.8zm86.2 75.3V138h46.8v16.4h-27v12.2h23.8v16.7h-23.9V197h27v16.2zm68.4 0V138h19.8v55.4h27.2v19.9zM233 138l30.7 27.9 30.7-27.9h8.9v75.3h-19.9v-38.7l-19.7 18-19.8-17.9v38.6H224V138z"
            fill="#0f1689"
          />
          <g fill="#0f1689" mask="url(#a)" transform="matrix(1 0 0 -1 1 404)">
            <path d="M223.7 143.4c5.6 4 18.7-5 29.2-20s14.4-30.2 8.7-34.2c-5.7-4-18.8 5-29.3 19.9s-14.3 30.3-8.6 34.3zM88.3 143.4c-5.7 4-18.8-5-29.3-20s-14.3-30.2-8.7-34.2 18.8 5 29.3 19.9 14.4 30.3 8.7 34.3zM155.9 120c-7 0-12.6-14.9-12.6-33.1 0-18.3 5.7-33.1 12.6-33.1 7 0 12.6 14.8 12.6 33 0 18.3-5.7 33.1-12.6 33.1z" />
          </g>
          <path
            d="M251.5 173.1a115.2 115.2 0 0 0-195.8-2.7m2.2 129.1a115 115 0 0 0 189.8-.1"
            mask="url(#a)"
            stroke="#0f1689"
            strokeWidth="20"
            transform="matrix(1 0 0 -1 1 404)"
          />
          <g fill="#0f1689" mask="url(#b)" transform="translate(1 -51)">
            <path d="M223.7 141c5.6 3.9 18.7-5 29.2-20s14.4-30.3 8.7-34.3c-5.7-4-18.8 5-29.3 20s-14.3 30.2-8.6 34.2zM88.3 141c-5.7 3.9-18.8-5-29.3-20s-14.3-30.3-8.7-34.3 18.8 5 29.3 20 14.4 30.2 8.7 34.2zM155.9 117.5c-7 0-12.6-14.8-12.6-33 0-18.4 5.7-33.2 12.6-33.2 7 0 12.6 14.8 12.6 33.1s-5.7 33-12.6 33z" />
          </g>
          <path
            d="M251.5 170.6A115.2 115.2 0 0 0 55.7 168m2.2 129.2a115 115 0 0 0 189.8-.2"
            mask="url(#b)"
            stroke="#0f1689"
            strokeWidth="20"
            transform="translate(1 -51)"
          />
        </g>
      </svg>
    );
  }
);
