import { useMutation, useQueryClient } from "react-query";

import { MUTATION_KEYS, QUERY_KEYS } from "config/queryKeys";
import { API_ENDPOINTS } from "config/apiPaths";
import { ClientApi } from "services/clientApi";
import { useToaster } from "hooks/useToaster";

export const useDeleteFoundationTemplate = () => {
  const queryClient = useQueryClient();
  const { addToast } = useToaster();

  return useMutation({
    mutationKey: [MUTATION_KEYS.deleteFoundationTemplate],
    mutationFn: (name: string) => {
      return ClientApi.delete(API_ENDPOINTS.deleteFoundationTemplate(name));
    },
    onSuccess: async () => {
      addToast ({
        message: `Foundation Template deleted successfully.`,
        type: "success",
      });
      setTimeout(() => {
        queryClient.invalidateQueries([QUERY_KEYS.foundationTemplates], {
          exact: false,
        });
      }, 5000); 
    },
    onError: async (error) => {
      addToast ({
        message: `Foundation Template deleting error. ${error}`,
        type: "error",
      }); 
    }
  });
};
  