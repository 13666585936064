
import { debounce, isArray } from "lodash";
import { useState } from "react";
import styles from "./GitopsAccountsListingContainer.module.css";
import { Searchbar } from "components/sredx-ui/Searchbar";
import { ViewToggler } from "components/sredx-ui/ViewToggler";
import { useGetGitopsAccounts } from "services/gitops/useGetGitopsAccounts";
import { Loader } from "components/sredx-ui";
import { Text } from "components/sredx-ui/Text";
import { Card } from "components/sredx-ui/Card";
import { Empty } from "components/sredx-ui/Empty";
import { GitopsAccountsListingCardContainer } from "./GitopsAccountsListingCardContainer/GitopsAccountsListingCardContainer";
import { GitopsAccountsListingDataTableContainer } from "./GitopsAccountsListingDataTableContainer/GitopsAccountsListingDataTableContainer";

interface GitopsAccountsListingContainerProps {
  onAddGitopsAccountClick:  () => void
}
export const GitopsAccountsListingContainer = ({onAddGitopsAccountClick}: GitopsAccountsListingContainerProps) => {
  const {
    data: gitopsAccounts,
    isFetching,
    isLoading, 
  } = useGetGitopsAccounts();

  //hooks
  const [view, setView] = useState<"card" | "datatable">("card");

  // Events handler
  const toggleView = () => {
    setView(view === "card" ? "datatable" : "card");
  };

  const handleSearch = (e: string) => {
    console.log(e)
  };
  const debouncedHandleSearch = debounce(handleSearch, 400);

  if (isLoading || isFetching)
    return (
      <div className={styles.loader_wrapper}>
        <Loader size={"large"}  />
        <Text>Loading Git Accounts...</Text>
      </div>
    );
    if (!gitopsAccounts || !isArray(gitopsAccounts)) {
      return (
        <Card className="mt-9">
          <Empty
            message={"No Git Accounts found for this control plane."}
            actionLabel={"Add new Git Account"}
            onActionClick={onAddGitopsAccountClick}
          />
        </Card>
      );
    }
    const resolveView = () => {
      if (view === "card") {
        return <GitopsAccountsListingCardContainer  gitopsAccounts={gitopsAccounts} />;
      }
      return <GitopsAccountsListingDataTableContainer gitopsAccounts={gitopsAccounts} />;
    };

  return (
    <div className={styles.wrapper}>
      {/*  Search & Filter  */}
      <div className={styles.search_filter_wrapper}>
        <div className={styles.searchbar_wrapper}>
          <Searchbar
            onChange={debouncedHandleSearch}
            placeholder="Search Git Accounts by name"
          />
        </div>
        <div className={styles.view_toggler}>
          <ViewToggler value={view} onToggle={toggleView} />
        </div>
      </div>
      {resolveView()}
    </div>
  );
  
};
