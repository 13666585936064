import { Button, ButtonLabel } from "components/sredx-ui/Button";
import styles from "./StacksPage.module.css";
import { PageContent } from "components/sredx-ui/PageContent";
import { Heading } from "components/sredx-ui/Text";
import { StacksListingContainer } from "containers/stacks/StacksListingContainer/StacksListingContainer";
import { useModal } from "hooks/useModal";
import { StackCreateContainer } from "containers/stacks/StackCreateContainer/StackCreateContainer";

const StacksPage = () => {
  const { openModal, closeModal } = useModal();

  const onAddStacksClick = () =>{
    openModal({
      content: <StackCreateContainer onCancel={closeModal} />
    });
  }

  return (
    <PageContent>
        <div className={styles.header_wrapper}>
          <div>
            <Heading>Stacks</Heading>
            <p>
              Preset your stacks in seconds
            </p>
          </div>
          <Button onClick={onAddStacksClick}>
            <ButtonLabel >Add new stack</ButtonLabel>
          </Button>
        </div>
        <StacksListingContainer onAddStackClick={onAddStacksClick} />
    </PageContent>
  );
};

export default StacksPage;
