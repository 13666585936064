
import { debounce, isArray } from "lodash";
import styles from "./StacksListingContainer.module.css";
import { Searchbar } from "components/sredx-ui/Searchbar";
import { Loader } from "components/sredx-ui";
import { Text } from "components/sredx-ui/Text";
import { Card } from "components/sredx-ui/Card";
import { Empty } from "components/sredx-ui/Empty";
import { useGetStacks } from "services/stacks/useGetStacks";
import { StacksListingCardContainer } from "./StacksListingCardContainer/StacksListingCardContainer";

interface StacksListingContainerProps {
  onAddStackClick:  () => void
}
export const StacksListingContainer = ({onAddStackClick}: StacksListingContainerProps) => {
  const {
    data: stacks,
    isFetching,
    isLoading, 
  } = useGetStacks();

  const handleSearch = (e: string) => {
    console.log(e)
  };
  const debouncedHandleSearch = debounce(handleSearch, 400);

  if (isLoading || isFetching || !stacks)
    return (
      <div className={styles.loader_wrapper}>
        <Loader size={"large"}  />
        <Text>Loading stacks...</Text>
      </div>
    );
    if (!stacks || !isArray(stacks)) {
      return (
        <Card className="mt-9">
          <Empty
            message={"No stacks found in this control plane."}
            actionLabel={"Add new stacks"}
            onActionClick={onAddStackClick}
          />
        </Card>
      );
    }

  return (
    <div className={styles.wrapper}>
      {/*  Search & Filter  */}
      <div className={styles.search_filter_wrapper}>
        <div className={styles.searchbar_wrapper}>
          <Searchbar
            onChange={debouncedHandleSearch}
            placeholder="Search stacks by name"
          />
        </div>
      </div>
      <StacksListingCardContainer  stacks={stacks} />
    </div>
  );
  
};
