import { useState } from "react";
import { useFormContext } from "react-hook-form";
import { nodeTypeEnum, nodeTypeEnumAws } from "types/clusters/clusters";

interface ClusterSelectNodeConfigProps {
  errors?: any;
  provider?: string;
}

export const ClusterSelectNodeConfig = ({
  errors,
  provider,
}: ClusterSelectNodeConfigProps) => {

  const [selected, setSelected] = useState<string>();
  const methods = useFormContext();
  const myoptions = nodeTypeOptions(provider)
  const handleSelect = (value: string) => {
      setSelected(value)
      methods.setValue("nodeType", value)
      return;
    }
  
    return (
      <div className={`flex flex-col gap-1 mt-2 mb-7`}>
        <div className="text-lg  mb-2">
          Node Configuration
        </div>
        <div className="grid grid-cols-4 gap-4 text-sm">
          {myoptions.map((item, i) =>
                <div
                  className={`border border-gray-400 flex flex-col p-4 text-gray-700 items-center gap-4 cursor-pointer ${
                    selected == item.value && "border-cyan-600 bg-cyan-50 text-cyan-700"
                  }`}
                  onClick={() => handleSelect(item.value)}
                >
                  <label className="font-semibold">{item.name}</label>
                  <div className="grid-cols-2 grid  items-center w-full">
                    <span className="text-gray-400 text-xs font-light">vCPUs</span>
                      <span className="">{item.vCPUs}</span>                    
                    <span className="text-gray-400 text-xs font-light">Memory</span>
                    <span className="">{item.memory}</span>     
                    
                    <span className="text-gray-400 text-xs font-light">Bandwidth</span>
                    <span className="">{item.network}</span>     
                  </div>
                </div>
        )}
        </div> 
        <span className="text-xs text-red-800">
          {errors?.type?.message}
        </span>
        
      </div>
    );
  
};


const nodeTypeOptions = (provider: string| undefined) => {
  if (provider === "scaleway") {
    return nodeTypeEnum;
  }
  if (provider === "aws") {
    return nodeTypeEnumAws;
  }
  return nodeTypeEnumAws;
};