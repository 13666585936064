
import { API_ENDPOINTS } from "config/apiPaths";
import { QUERY_KEYS } from "config/queryKeys";
import { useQuery } from "react-query";
import { ClientApi } from "services/clientApi";
import { DashboardCounterDto } from "types/dashboard";


export const useGetDashboardCounters = () => {
  return useQuery<DashboardCounterDto[]>({
    queryKey: [QUERY_KEYS.dashboardCounts],
    queryFn: async () =>
      await ClientApi.get(API_ENDPOINTS.getDashboardCounts()),
    retry: 5,
  retryDelay: 1000,
  });
};