import styles from "./FoundationTemplatesDetailsModal.module.css";
import { Card } from "components/sredx-ui/Card";
import { Button } from "components/sredx-ui/Button";
import { FoundationTemplatesDto } from "types/foundationtemplates/foundationtemplates";
import { useDeleteFoundationTemplate } from "services/foundationtemplates/useDeleteFoundationTemplate";
import { resolveToolUrl } from "components/clusters/utils";
import { StatusTag } from "components/shared/StatusTag/StatusTag";

interface FoundationTemplatesDetailsModalProps {
  foundationTemplate: FoundationTemplatesDto;
  onClose: () => void;
}

export const FoundationTemplatesDetailsModal = ({
  foundationTemplate,
  onClose,
}: FoundationTemplatesDetailsModalProps) => {
  const { mutate: deleteCluster } = useDeleteFoundationTemplate();

  const handleDeleteFoundationTemplate = () => {
    if (foundationTemplate) {
      deleteCluster(foundationTemplate.name, {
        onSuccess: () => {
          onClose();
        },
        onError: (error: any) => {
          console.error(error)
        },
      });
    }
  };
  const ToolImg = resolveToolUrl(foundationTemplate.name);
  return (
    <Card>
      <div className={styles.wrapper}>
        <div className={styles.content}>
      <div className="flex justify-between">
        <div className={styles.head}>
          <h2 className="text-lg font-bold flex uppercase text-gray-800">
           <ToolImg className="h-7 mr-3 mb-3" />
            {foundationTemplate.name}
            </h2>
        </div>
        <div className="flex gap-2 flex-wrap">
          <StatusTag status={foundationTemplate.phase == "READY" ? "STABLE" : foundationTemplate.phase} />
        </div>
      </div>
      {/* Body Section */}
      <div className="grid grid-cols-2 gap-4">
          <h3 className="Semibold">Name :</h3>
          <span className="text-slate-700 ml-5">{foundationTemplate.name}</span>
        </div>
        <div className="grid grid-cols-2 gap-4">
          <h3 className="Semibold">Type :</h3>
          <span className="text-slate-700 ml-5">{foundationTemplate.type}</span>
        </div>
        <div className="grid grid-cols-2 gap-4">
          <h3 className="Semibold">Chart :</h3>
          <span className="text-slate-700 ml-5">{foundationTemplate.chart}</span>
        </div>
        <div className="grid grid-cols-2 gap-4">
          <h3 className="Semibold">Repository URL :</h3>
          <span className="text-slate-700 ml-5">{foundationTemplate.repoUrl}</span>
        </div>
        <div className="grid grid-cols-2 gap-4">
          <h3 className="Semibold">Version :</h3>
          <span className="text-slate-700 ml-5">{foundationTemplate.version}</span>
        </div>
      {
        foundationTemplate.phase != "READY" && foundationTemplate.conditions?.filter(condition => condition.status != false).length > 0 &&
        <div className="grid grid-cols-2 gap-4">
          <h3 className="Semibold">Error Message :</h3>
          <span className="text-red-500 text-xs">{foundationTemplate.conditions?.length > 0 && foundationTemplate.conditions[0].message}</span>
        </div>
      }
      
      
      
        </div>
        <div className={styles.actions}>
        <Button ghost onClick={onClose}>
            Close
          </Button>
          <Button
            variant="danger"
            onClick={handleDeleteFoundationTemplate}
            className={styles.deleteButton}
          >
            Delete
          </Button>
         
        </div>
      </div>
    </Card>
  );
};
