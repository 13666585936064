import { CLUSTERS_PAGE } from "routes/paths";
import { ClusterDetailsDeleteContainer } from "./ClusterDetailsDeleteContainer/ClusterDetailsDeleteContainer";
import { ClusterDetailsToolsContainer } from "./ClusterDetailsToolsContainer";
import ClusterManagedResourcesContainer from "../ClusterManagedResourcesContainer/ClusterManagedResourcesContainer";
import ClusterDetailsInfoFetchContainer from "../ClusterDetailsFetchContainer/ClusterDetailsInfoFetchContainer";
import { AppReturnButton } from "components/Layout";
import { Cluster } from "types/clusters/clusters";

export const ClusterDetailsContainer = ({cluster}: {cluster: Cluster } ) => {
  return (
    <>
      <AppReturnButton
        to={CLUSTERS_PAGE}
        label={"Back to clusters"}
      />

      <div className="flex flex-col gap-6">
        <ClusterDetailsInfoFetchContainer cluster={cluster} />
        <ClusterManagedResourcesContainer name={cluster?.name} />
        {cluster?.status?.phase == "READY" && <ClusterDetailsToolsContainer id={cluster?.name} />}
        <ClusterDetailsDeleteContainer id={cluster?.name} />
      </div>
    </>
  );
};
