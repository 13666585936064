import { useState } from "react";
import { useFormContext } from "react-hook-form";
import { resolveToolUrl } from "../../utils";
import { gitopsToolsEnum } from "types/clusters/clusters";

interface ClusterSelectGitopsToolProps {
  errors?: any;
}

export const ClusterSelectGitopsTool = ({
  errors,
}: ClusterSelectGitopsToolProps) => {

  const [selected, setSelected] = useState<string>();
  const methods = useFormContext();
  // please add errors handling
  const handleSelect = (value: string) => {
    setSelected(value);
    methods.setValue("gitOpsTool", value);
    return;
  };
  
  return (
    <div className={`flex flex-col gap-1 mt-2 mb-7`}>
      <div className="text-lg mb-2">
        Gitops Tool
      </div>
      <div className="grid grid-cols-4 gap-4">
        {gitopsToolsEnum.map((item, i) => {
          const ToolImg = resolveToolUrl(item);
            return ToolImg ? (
              <div
                className={`border border-gray-400 text-gray-700 flex px-4 p-2 items-center gap-4 cursor-pointer ${
                  selected == item && " border-cyan-600 bg-cyan-50 text-cyan-700"
                }`}
                onClick={() => handleSelect(item)}
              >
                <ToolImg toolName={item} className="h-4" />
                <div>{item}</div>
              </div>
            ) : null;
        }).filter(Boolean)}
      </div>
      <span className="text-xs text-red-800">
        {errors?.version?.message}
      </span>
      
    </div>
  );
};