declare global {
  interface Window {
    REACT_APP_KEYCLOAK_URL: string;
    REACT_APP_KEYCLOAK_REALM: string;
    REACT_APP_KEYCLOAK_CLIENT_ID: string;
    REACT_APP_API_URL: string;
    REACT_APP_KEYCLOAK_REDIRECT_URI: string;
  }
}
export const REACT_APP_KEYCLOAK_URL =
  process.env?.REACT_APP_KEYCLOAK_URL || window.REACT_APP_KEYCLOAK_URL;
export const REACT_APP_KEYCLOAK_REALM =
  process.env?.REACT_APP_KEYCLOAK_REALM || window.REACT_APP_KEYCLOAK_REALM;
export const REACT_APP_KEYCLOAK_CLIENT_ID =
  process.env?.REACT_APP_KEYCLOAK_CLIENT_ID ||
  window.REACT_APP_KEYCLOAK_CLIENT_ID;
export const REACT_APP_API_URL =
  process.env?.REACT_APP_API_URL || window.REACT_APP_API_URL;
export const REACT_APP_KEYCLOAK_REDIRECT_URI =
  process.env?.REACT_APP_KEYCLOAK_REDIRECT_URI ||
  window.REACT_APP_KEYCLOAK_REDIRECT_URI;