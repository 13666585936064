import { DownloadIcon } from "@heroicons/react/outline";

import { MouseEventHandler } from "react";
import styles from "./ClusterCardComponent.module.css";
import { Card, CardBody } from "components/sredx-ui/Card";
import { Heading, Text } from "components/sredx-ui/Text";
import { Button, ButtonIcon, ButtonLabel } from "components/sredx-ui/Button";
import { ClusterCardStatusIndicator } from "../ClusterCardStatusIndicator";
import { ClusterCardProperties } from "../ClusterCardProperties";
import { ClusterCardBadges } from "../ClusterCardBadges";
import { ClusterCardTools } from "../ClusterCardTools";
import { Cluster } from "types/clusters/clusters";
import { resolveClusterBadges, resolveClusterProperties } from "../helpers";
import Tooltip from "components/sredx-ui/Tootlip/Tooltip";

interface ClusterCardComponentProps {
  cluster: Cluster;
  onDownload?: (clusterName: string) => void;
  isButtonLoading: boolean;
  isDownloadEnabled: boolean;
}

export const ClusterCardComponent = ({
  cluster,
  onDownload,
  isButtonLoading,
  isDownloadEnabled,
}: ClusterCardComponentProps) => {
  const clusterProperties = resolveClusterProperties(cluster);
  const clusterBadges = resolveClusterBadges(cluster);

  const handleOnClick: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    if (onDownload) {
      onDownload(cluster.name);
    }
  };
  return (
    <Card clickable>
      <CardBody>
        <div className={styles.wrapper}>
          <div className={styles.head}>
            <Heading level={3}>{cluster.name}</Heading>
            {renderDownloadButton(isDownloadEnabled, handleOnClick, isButtonLoading, isDownloadEnabled)}
          </div>
          <ClusterCardStatusIndicator status={cluster.status.phase} />
          <ClusterCardProperties properties={clusterProperties} />
          <ClusterCardBadges badges={clusterBadges} />
          {cluster.tools && <ClusterCardTools tools={cluster.tools} />}
        </div>
      </CardBody>
    </Card>
  );
};

const renderDownloadButton = (isKubeDownloadReady: boolean, handleOnClick: (e: React.MouseEvent<HTMLButtonElement>) => void, isButtonLoading: boolean, isDownloadEnabled: boolean) => {
  if(isKubeDownloadReady){
    return (
      <Button
      variant={"neutral"}
      ghost
      isLoading={isButtonLoading}
      onClick={handleOnClick}
      style={{
        color: "#0b6cfe",
      }}
    >
      <ButtonIcon>
        <DownloadIcon />
      </ButtonIcon>
      <ButtonLabel>
        <Text>KubeConfig</Text>
      </ButtonLabel>
    </Button>
  )
  }else{
    return (
      <Tooltip content="Please wait for the cluster to be ready." direction='bottom'>
          <Button
            variant={"neutral"}
            ghost
            disabled={!isDownloadEnabled}
          >
            <ButtonIcon>
              <DownloadIcon />
            </ButtonIcon>
            <ButtonLabel>
              <Text>KubeConfig</Text>
            </ButtonLabel>
          </Button>
        </Tooltip>
    )
  }
}