import { API_ENDPOINTS } from "config/apiPaths";
import { QUERY_KEYS } from "config/queryKeys";
import { useQuery } from "react-query";
import { ClientApi } from "services/clientApi";
import { Cluster } from "types/clusters/clusters";

export const useGetClusters = () => {
  return useQuery<Cluster[]>({
    queryKey: [QUERY_KEYS.clusters],
    queryFn: async () => await ClientApi.get(API_ENDPOINTS.getClusters()),
    retry: 5,
    retryDelay: 1000,
  });
};
