import { FormField } from "components/basic/FormField";
import { SelectInput } from "components/basic/SelectInput";
import { TextInput } from "components/sredx-ui/Forms/TextInput";
import { supportedProvidersEnum } from "types/clusters/clusters";

interface CspAccountCreateFormProps {
  errors?: any;
  provider: string;
}
export const CspAccountCreateForm = ({
  errors,
  provider,
}: CspAccountCreateFormProps) => {
  return (
    <div className={`flex flex-col gap-4`}>
      <FormField
        id="name"
        name="name"
        label="Csp Account name"
        placeholder="Enter a csp account name"
        component={TextInput}
        error={errors?.name?.message}
      />
        <FormField
        id="provider"
        name="provider"
        label="Provider name"
        placeholder="Select provider name"
        defaultValue={supportedProvidersEnum[0]}
        className="w-full"
        component={SelectInput}
        options={supportedProvidersEnum?.map((provider) => {
            return {
              value: provider,
              label: (
                <>
                  {provider}
                </>
              ),
            };
        })}
        errors={errors?.gitOpsName?.message}
      />
        
      <FormField
        id="accessKey"
        name="accessKey"
        label="Access Key"
        placeholder="Enter Access Key"
        type="password"
        component={TextInput}
        error={errors?.accessKey?.message}
      />
      <FormField
        id="secretKey"
        name="secretKey"
        label="Secret Key"
        placeholder="Enter Secret Key"
        type="password"
        component={TextInput}
        error={errors?.secretKey?.message}
      />
      {provider === "scaleway" && (
      <FormField
          id="projectId"
          name="projectId"
          label="Project Id"
          className="w-full"
          placeholder="Enter Project Id"
          component={TextInput}
          error={errors?.projectId?.message}
        />
      )}
    </div>
  );
};
