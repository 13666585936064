import React from "react";


import styles from "./HeaderUserActions.module.css";
import { Text } from "components/sredx-ui/Text";
import { LogoutIcon } from "components/Layout/Icon";

export const LogoutAction = () => {
  return (
    <div className={styles.wrapper}>
      <Text size={"md"}>Logout</Text>
      <LogoutIcon className={styles.icon} />
    </div>
  );
};

// 👇 The rest of the actions go down here 👇
