import { GitopsAccountDeleteModal } from "components/gitopsAccounts/GitopsAccountDeleteModal";
import styles from "./GitopsAccountsListingDataTableContainer.module.css";
import { GitopsAccountsDatatable } from "components/gitopsAccounts/GitopsAccountsDatatable";
import { GitopsAccountsDto } from "types/gitops/gitops";
import { useModal } from "hooks/useModal";

interface GitopsAccountsListingCardContainerProps {
  gitopsAccounts: GitopsAccountsDto[]
}

export const GitopsAccountsListingDataTableContainer = ({gitopsAccounts}: GitopsAccountsListingCardContainerProps) => {
  const { openModal, closeModal } = useModal();

  // handlers
  const handleDeleteGitopsAccount = (gitopsAccount: GitopsAccountsDto) => {
    openModal({
      content: (
        <GitopsAccountDeleteModal
          gitopsAccount={gitopsAccount}
          onClose={closeModal}
        />
      ),
    });
  };

  return (
    <div className={styles.datatable_wrapper}>
      <GitopsAccountsDatatable
        gitopsAccounts={gitopsAccounts}
        onDelete={handleDeleteGitopsAccount}
      />
    </div>
  );
};
