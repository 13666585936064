import { API_ENDPOINTS } from "config/apiPaths";
import { QUERY_KEYS } from "config/queryKeys";
import { ClientApi } from "services/clientApi";
import { useQuery } from "react-query";
import { Controlplane } from "types/controlplanes/controlplanes";

export const useGetControlplaneDetailInfo = (clusterName: string) => {
  return useQuery<Controlplane>({
    queryKey: [QUERY_KEYS.ControlplaneDetails],
    queryFn: async () => await ClientApi.get(API_ENDPOINTS.getControlplaneDetails(clusterName)),
    retry: 5,
    retryDelay: 1000,
  });
};
