// import styles from "./styles.module.css";
import { ListingCard } from "components/sredx-ui/ListingCard";
interface CounterCardProps {
  title: string;
  count: number;
}
 
export const CounterCard = ({title, count}: CounterCardProps) => {
  return (
    <ListingCard>
        <div className="items-center text-center"> 
            <h2 className="uppercase text-3xl font-bold mb-5">
              {count}
            </h2>
            <h2 className="uppercase text-xl font-bold text">
              {title}
            </h2>
        </div>
    </ListingCard>
  );
};