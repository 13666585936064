import { GitopsAccountDeleteModal } from "components/gitopsAccounts/GitopsAccountDeleteModal";
import styles from "./GitopsAccountsListingCardContainer.module.css";
import { useModal } from "hooks/useModal";
import { GitopsAccountsDto } from "types/gitops/gitops";
import { GitopsAccountCard } from "components/gitopsAccounts/GitopsAccountCard/GitopsAccountCard";


interface GitopsAccountsListingCardContainerProps {
  gitopsAccounts: GitopsAccountsDto[]
}
export const GitopsAccountsListingCardContainer = ({gitopsAccounts}: GitopsAccountsListingCardContainerProps) => {
  //hooks
  const { openModal, closeModal } = useModal();

  // handlers
  const handleDeleteGitopsAccount = (gitopsAccount: GitopsAccountsDto) => {
    openModal({
      content: (
        <GitopsAccountDeleteModal
          gitopsAccount={gitopsAccount}
          onClose={closeModal}
        />
      ),
    });
  };

  return (
    <>
      <div className={styles.cardlist_wrapper}>
        {gitopsAccounts?.map((gitopsAccount) => (
          <GitopsAccountCard
            key={gitopsAccount.name}
            gitopsAccount={gitopsAccount as GitopsAccountsDto}
            onDelete={handleDeleteGitopsAccount}
          />
        ))}
      </div>
    </>
  );
};
