import { useEffect, useState } from "react";

interface ToolSvgIconProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  toolName?: string;
}

const ToolSvgIcon: React.FC<ToolSvgIconProps> = ({ toolName, ...props }) => {
  const [iconSrc, setIconSrc] = useState<string | null>(null);
  toolName= toolName?.toLowerCase()

  useEffect(() => {
    // Assuming the SVG files are named after the tool names and stored in a specific folder
    const importIcon = async () => {
      try {
        const icon = await import(`./svgs/${toolName}.svg`);
        setIconSrc(icon.default);
      } catch (error) {
        console.error(`Icon for ${toolName} not found`, error);
        setIconSrc(null);
      }
    };

    importIcon();
  }, [toolName]);

  return iconSrc ? <img src={iconSrc} alt={toolName} {...props} /> : null;
};

export default ToolSvgIcon;
