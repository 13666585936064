// import { DownloadIcon } from '@heroicons/react/solid';
import Tooltip from 'components/sredx-ui/Tootlip/Tooltip';
import styles from './clusterDetails.module.css'
import { Button } from 'components/sredx-ui/Button';

interface ClusterDetailsHeaderBarProps {
    heading: string;
    onDownload: () => void;
    isKubeDownloadReady: boolean
    isDownloading: boolean;
}

const ClusterDetailsHeaderBar = ({heading, onDownload, isKubeDownloadReady, isDownloading}: ClusterDetailsHeaderBarProps) => {
  
  
  return (
    <div className={styles.header} >
        <div role="heading" aria-level={2} className={styles.mainHeading} >
        {heading}
        </div>
        {renderDownloadButton(onDownload, isDownloading, isKubeDownloadReady)}
  </div>
  )
}
const renderDownloadButton = (onDownload: () => void | undefined, isDownloading: boolean, isKubeDownloadReady: boolean) => {
  if(isKubeDownloadReady){
    return (
      <Button 
      variant="primary" 
      onClick={onDownload} 
      isLoading={isDownloading}
      disabled={!isKubeDownloadReady}
    >
      Download Kubeconfig
    </Button>
  )
  }else{
    return (
      <Tooltip content="Please wait for the cluster to be ready." direction='bottom'>
          <Button 
          variant="primary" 
          disabled={true}
        >
          Download Kubeconfig
        </Button>
    </Tooltip>
    )
  }
}
export default ClusterDetailsHeaderBar