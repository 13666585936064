import { TextInput } from "components/sredx-ui/Forms/TextInput";
import styles from "./StackFoundationsDetailsModal.module.css";
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useGetFoundationTemplates } from "services/foundationtemplates/useGetFoundationTemplates";
import { Text } from "components/sredx-ui/Text";
import { FoundationTemplatesDto } from "types/foundationtemplates/foundationtemplates";
import { resolveToolUrl } from "components/clusters/utils";
import { Loader } from "components/sredx-ui";
import { StacksDto } from "types/stacks/stacks";

interface StackFoundationsDetailsModalProps {
  display: boolean;
  stack: StacksDto;
}
export const StackFoundationsDetailsModal = ({
  display,
  stack,
}: StackFoundationsDetailsModalProps) => {
  const {
    data: foundationTemplates,
    isFetching,
    isLoading, 
  } = useGetFoundationTemplates();
  
  const [selected, setSelected] = useState<string[]>([]);
  const methods = useFormContext();
  const [displayedTools, setDisplayedTools] = useState<FoundationTemplatesDto[]>(foundationTemplates ? foundationTemplates : []);
  
  useEffect(() => {
    if (!isFetching && !isLoading && foundationTemplates) {
      setDisplayedTools(foundationTemplates);
      setSelected(stack.foundationTemplateRefs.map(stack=> stack.name));
    }
  }, [isFetching, isLoading, foundationTemplates, stack.foundationTemplateRefs]);

  if(isFetching || isLoading || !foundationTemplates){
    return (
      <div className={styles.loader_wrapper}>
        <Loader size={"large"}  />
        <Text>Loading foundation templates...</Text>
      </div>
    );
  }
  

  const handleSearch = (value: string) => {
      let filteredFoundations = foundationTemplates?.filter((tool) =>
        tool.name.toLocaleLowerCase().includes(value.toLowerCase())
      )
      setDisplayedTools(filteredFoundations ? filteredFoundations : []);
    
  };

  const handleSelect = (value: string) => {
    if (selected.includes(value)) {
      setSelected(selected.filter((item: any) => item !== value));
      methods.setValue(
        "foundationTemplateRefs",
        selected.filter((item: any) => item !== value)
      );
      return;
    }
    setSelected([...selected, value]);
    methods.setValue("foundationTemplateRefs", [...selected, value]);
    return;
  };
  if (isLoading || isFetching || !foundationTemplates )
    return (
      <div>
        <Text>Loading Foundation Templates...</Text>
      </div>
    );
    if(!Array.isArray(foundationTemplates)){
      return (
        <div>
          <Text>You have no foundation templates...</Text>
        </div>
      );
    }
  return (
    <div className={`flex flex-col gap-4 ${display ? "" : "hidden"}`}>
      <div>
        Foundations
        <TextInput
          placeholder="Search"
          onChange={(e: any) => handleSearch(e.target.value)}
        />
      </div>
      {/* yeah */}
      <div className="h-96 overflow-auto">
        <div className="grid grid-cols-2 gap-4">
          {displayedTools.filter(item => item.phase=="READY").map((item, i) => {
            const ToolImg = resolveToolUrl(item.name);
              return ToolImg ? (
                <div
                  className={`border flex px-4 p-2 items-center gap-4 cursor-pointer ${
                    selected.includes(item.name) && " border-black bg-gray-50"
                  }`}
                  onClick={() => handleSelect(item.name)}
                >
                  <ToolImg toolName={item.chart} className="h-10" />
                  <div>{item.name}</div>
                </div>
              ) : null;
          }).filter(Boolean)}
        </div>
      </div>
    </div>
  );
};