import { FormField } from "components/basic/FormField";
import { TextInput } from "components/sredx-ui/Forms/TextInput";

interface StackCreateFormProps {
  errors?: any;
  display: boolean;
}
export const StackCreateForm = ({
  errors,
  display,
}: StackCreateFormProps) => {
  return (
    <div className={`flex flex-col gap-4  ${display ? "" : "hidden"}`}>
      <FormField
        id="name"
        name="name"
        label="Stack name"
        placeholder="Enter Stack name"
        component={TextInput}
        error={errors?.name?.message}
      />
      
       <FormField
        id="description"
        name="description"
        label="Description"
        placeholder="Enter a stack description "
        className="w-full"
        component={TextInput}
        error={errors?.description?.message}
      />
      
    </div>
  );
};