import { IconContext } from "react-icons";
import { resolveToolUrl } from "../../utils";
import { StacksDto } from "types/stacks/stacks";

interface StacksFoundationsCardsProps{
    stack: StacksDto,
    selected: boolean, 
    handleSelect: (name: string, values: string[]) => void
}
export const StacksFoundationsCards = ({stack, selected, handleSelect}:StacksFoundationsCardsProps) => {

              return (
                <div
                  className={`border px-4 p-2 stacks-center gap-4 cursor-pointer ${
                    selected && " border-sky-500 text-sky-700 bg-gray-50"
                  }`}
                  onClick={() => handleSelect(stack.name, stack.foundationTemplateRefs.map(val=> val.name))}
                >
                  <div className="w-full px-2 cursor-pointer border-t-0 rounded-r-lg rounded-l-lgpt-2 mt-2">
                    <span className="font-semibold">Stack: {stack.name}</span>
                    <div className=" grid grid-cols-4 mt-5">
                    {stack.foundationTemplateRefs?.map((foundationTemplate) => {
                    const ToolImg = resolveToolUrl(foundationTemplate.name);

                    return (
                        <div className="mb-5">
                        <IconContext.Provider value={{size: "3.5rem",color: "" ,className: "mb-3 to-blue-900" }}>
                            <ToolImg toolName={foundationTemplate.name} className="h-14 mb-3" />
                        </IconContext.Provider>
                        <div>
                            <h2>
                            {foundationTemplate.name}
                            </h2>
                            <p className="text-xs">
                            v{foundationTemplate.version}
                            </p>
                        </div>
                        </div>
                    )})}
                    </div>
                    </div>
                </div>
              ) ;
};