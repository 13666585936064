import { useState } from "react";
import { useFormContext } from "react-hook-form";
import { resolveToolUrl } from "../../utils";

interface ClusterSelectProviderAccountProps {
  errors?: any;
  cloudAccounts: { name: string; provider: string }[];
}

export const ClusterSelectProviderAccount = ({
  errors,
  cloudAccounts,
}: ClusterSelectProviderAccountProps) => {

  const [selected, setSelected] = useState<string>();
  const methods = useFormContext();

  // please add errors handling
  const handleSelect = (value: string) => {
      setSelected(value)
      methods.setValue("accountName", value)
      
      return;
    }
  
  
  return (
    <div className={`flex flex-col gap-1 mt-2 mb-7`}>
      <div className="text-lg mb-2">
        Cloud Provider Account
      </div>
      <div className="grid grid-cols-4 gap-4">
        {cloudAccounts.map((item, i) => {
          const ToolImg = resolveToolUrl(item.provider);
            return ToolImg ? (
              <div
                className={`border border-gray-400 flex px-4 p-3 text-gray-700 items-center gap-4 cursor-pointer ${
                  selected == item.name && " border-cyan-600 bg-cyan-50 text-cyan-700"
                }`}
                onClick={() => handleSelect(item.name)}
              >
                <ToolImg toolName={item.provider} className="h-4" />
                <div>{item.name}</div>
              </div>
            ) : null;
        }).filter(Boolean)}
      </div> 
      <span className="text-xs text-red-800">
        {errors?.accountName?.message}
      </span>
      
    </div>
  );
};