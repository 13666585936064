
import { API_ENDPOINTS } from "config/apiPaths";
import { QUERY_KEYS } from "config/queryKeys";
import { useQuery } from "react-query";
import { ClientApi } from "services/clientApi";
import { KubeConfigResponseDto } from "types/kubeconfig";



export const useGetControlplaneKubeConfig = () => {
  return useQuery<KubeConfigResponseDto>({
    queryKey: [QUERY_KEYS.controlplaneKubeConfig],
    queryFn: async () =>
      await ClientApi.get(API_ENDPOINTS.getControlplaneKubeConfig()),
    retry: false,
  });
};
