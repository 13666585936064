import { StatusType } from "types/clusters/clusters";
import * as yup from "yup";

export interface FoundationTemplatesDto {
    name: string;
    phase: StatusType;
    message: string;
    repoUrl: string;
    version: string;
    chart: string;
    type: string;
    conditions: Conditions[];
  }
  export interface Conditions {
    status: boolean;
    message: string;
  }
  export interface FoundationTemplatesCreationDto {
    name: string;
    repoUrl: string;
    version: string;
    type: string;
    chart: string;
  }
  export const foundationTemplateCreationDSchema = yup.object().shape({
    name: yup.string().required(),
    repoUrl: yup.string().required(),
    version: yup.string().required(),
    chart: yup.string().required(),
    type: yup.string().required(),
  });