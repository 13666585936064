import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { Header } from "components/Landing/header/Header";
import styles from "./LandingPage.module.css";
import { FeaturesData } from "./FeatureData";
import FeaturesSection from "../../components/Landing/features/FeaturesSection";
import CallForAction from "../../components/Landing/call-for-action/CallForAction";
import clsx from "clsx";
import { Footer } from "components/shared/Footer";
import "styles/layout.css";
import { NewHero } from "components/Landing/hero/NewHero";
import { useAuth } from "react-oidc-context";
import { HOME_PAGE } from "routes/paths";

export const LandingPage = () => {
  const { isAuthenticated, signinRedirect } = useAuth();
  const [isHeaderBgVisible, setIsHeaderBgVisible] = useState(false);

  useEffect(() => {
    const options = {
        root: null,
        rootMargin: "0px",
        threshold: 0.5,
      },
      observer = new IntersectionObserver((entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsHeaderBgVisible(true);
          } else {
            setIsHeaderBgVisible(false);
          }
        });
      }, options);
    if (document.querySelector("#hero"))
      observer.observe(document.querySelector("#hero") as HTMLElement);
  }, []);

  const handleOnGetStarted = () => {
    signinRedirect();
  };

  if (isAuthenticated) {
    return <Navigate to={HOME_PAGE} />;
  }

  return (
    <>
      <Header onLogin={signinRedirect} backgroundVisible={isHeaderBgVisible} />
      <div className={styles.page_font}>
        <div className={styles.container_black} id="hero">
          <NewHero onGetStarted={handleOnGetStarted} />
        </div>

        <div className={clsx(styles.container_white)}>
          <FeaturesSection featureData={FeaturesData} />
        </div>

        <div className={clsx(styles.container_black)}>
          <CallForAction onGetStarted={handleOnGetStarted} />
        </div>

        <Footer />
      </div>
    </>
  );
};
