import { useModal } from "hooks/useModal";
import styles from "./ControlplanesDetailsDeleteContainer.module.css";
import { useNavigate } from "react-router-dom";
import { CONTROLPLANS_PAGES } from "routes/paths";
import { ClusterDeleteModal } from "components/clusters/ClusterDetails/ClusterDeleteModal";
import { useDeleteControlplane } from "services/controlplanes/useDeleteControlplane";

interface ControlplaneDetailsDeleteContainerProps {
  id: string;
}
export const ControlplaneDetailsDeleteContainer = ({
  id,
}: ControlplaneDetailsDeleteContainerProps) => {
  const { closeModal, openModal  } = useModal();
  const navigate = useNavigate();
  const { mutate: deleteControlplane } = useDeleteControlplane();

  const handleDeleteControlplane = () => {
      deleteControlplane(id, {
        onSuccess: () => {
          closeModal();
          navigate(CONTROLPLANS_PAGES);
        },
        onError: (error: any) => {
          console.error(error)
        },
      });
  };

  const onDeleteControlplane = () => {
    openModal({
      content: (<ClusterDeleteModal closeModal={closeModal} handleDeleteControlplane={handleDeleteControlplane}  />),
      options: {
        width: "1000px",
      },
    });
  };

  return (
    <div className={styles.wrapper}>
      <div>
        <h3 className={styles.title}>Delete this cluster</h3>
        <p>
          Deleting your cluster will permanently destroy your cluster and all of
          its associated pools and Instances.
        </p>
      </div>
      <button onClick={onDeleteControlplane} className={styles.deleteButton}>
        Delete this cluster
      </button>
    </div>
  );
};
