import clsx from "clsx";
import styles from "./HeaderLogo.module.css";
import ClusterdialiLogo from "components/Landing/header/ClusterdialiLogo";

export const HeaderLogo = () => {
  return (
    <div className={clsx(" nemo-header-logo", styles.logo)}>
        <ClusterdialiLogo/>
    </div>
  );
};
