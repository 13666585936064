import { FormField } from "components/basic/FormField";
import { TextInput } from "components/sredx-ui/Forms/TextInput";

interface GitopsAccountCreateFormProps {
  errors?: any;
}
export const GitopsAccountCreateForm = ({
  errors,
}: GitopsAccountCreateFormProps) => {
  return (
    <div className={`flex flex-col gap-4`}>
      <FormField
        id="name"
        name="name"
        label="Gitops Account name"
        placeholder="Enter a gitops account name"
        component={TextInput}
        error={errors?.name?.message}
      />
      <FormField
        id="owner"
        name="owner"
        label="Owner"
        placeholder="Enter owner username"
        component={TextInput}
        error={errors?.owner?.message}
      />
      <FormField
        id="accessToken"
        name="accessToken"
        label="Access Token"
        placeholder="Enter Access Token"
        type="password"
        component={TextInput}
        error={errors?.accessToken?.message}
      />
    </div>
  );
};
