import styles from "./FeatureCard.module.css";
import clsx from "clsx";
import { Card, CardBody } from "components/sredx-ui/Card";
import { Heading, Text } from "components/sredx-ui/Text";
import { capitalizeFirstLetter } from "utils/formatting";
import { FeatureCardDto } from "./types";

interface FeatureCardProps {
  redirect: FeatureCardDto;
  onClick?: (achievement: FeatureCardDto) => void;
  isColored?: boolean;
  displayScoreCard?: boolean;
}

export const FeatureCard = ({
  redirect,
  onClick,
  isColored = true,
  displayScoreCard = true,
}: FeatureCardProps) => {
  // Derived variables
  const { featureDescription, featureName, icon } = redirect;

  // Temp --------
  const levelImage = "/images/" + icon;
  // --------------

  // Events handlers
  const handleOnClick = () => {
    onClick && onClick(redirect);
  };
  const ConditionalCard = ({ disabled, children }: any) =>
    disabled ? <>{children}</> : <Card clickable={!!onClick}>{children}</Card>;

  return (
    <div className={styles.achievement_wrapper} onClick={handleOnClick}>
      <ConditionalCard disabled={!displayScoreCard}>
        <CardBody>
          <div
            className={clsx(
              styles.body_wrapper,
              !isColored ? styles.disabled : ""
            )}
          >
            <figure className={styles.level_image}>
              <img src={levelImage} alt="level" />
            </figure>
            <div className={styles.info}>
              <Heading level={5}>{capitalizeFirstLetter(featureName)}</Heading>
              {displayScoreCard && <Text className="mt-10" size={"sm"}>{featureDescription}</Text>}
            </div>
          </div>
        </CardBody>
      </ConditionalCard>
    </div>
  );
};