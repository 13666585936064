import { Loader } from "components/sredx-ui";
import "./LoadingPage.css";

export const LoadingPage = () => {
  return (
    <div id={"loading-spinner"}>
      <div className="fixed top-0 left-0 z-50 w-screen h-screen flex items-center justify-center bg-white">
        <Loader size={"large"} />
      </div>
    </div>
  );
};
