import { BreadCrumb } from "./types";

export const generateBreadcrumbs = (routes: any[], pathname: string) => {
  const breadcrumbs: BreadCrumb[] = [];
  const findRoute = (routeList: any[], currentPath = "") => {
    for (const route of routeList) {
      const fullPath = `${currentPath}/${route.path}`.replace(/\/+/g, "/");
      if (pathname.includes(fullPath) && route.crumb) {
        breadcrumbs.push({
          label: route.crumb,
          path: fullPath,
        });
        if(fullPath !== pathname){
          breadcrumbs.push({
            label:  pathname.slice(pathname.lastIndexOf('/')).slice(1),
            path: "#",
          });
        }
      }
      if (route.children) {
        findRoute(route.children, fullPath);
      }
    }
  };

  findRoute(routes);
  return breadcrumbs;
};
