import { useMutation, useQueryClient } from "react-query";

import { MUTATION_KEYS, QUERY_KEYS } from "config/queryKeys";
import { API_ENDPOINTS } from "config/apiPaths";
import { ClientApi } from "services/clientApi";
import { StackCreationDto } from "types/stacks/stacks";

export const useCreateStack = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: [MUTATION_KEYS.createStack],
    mutationFn: (data: StackCreationDto) => {
      return ClientApi.post(API_ENDPOINTS.createStack(), data);
    },
    onSuccess: async () => {
      
      setTimeout(() => {
        queryClient.invalidateQueries([QUERY_KEYS.stacks]);
      }, 1000); 
    },
  });
};
  