import { useEffect, useState } from "react";
import styles from "./ClusterManagedResourcesContainer.module.css";
import { useToaster } from "hooks/useToaster";
import { ManagedResource } from "types/clusters/clusters";
import { TextInput } from "components/sredx-ui/Forms/TextInput";
import ManagedResourcesSkeleton from "components/clusters/ClusterDetails/ManagedResources/ManagedResourcesSkeleton";
import ManagedResources from "components/clusters/ClusterDetails/ManagedResources/ManagedResources";
import { useGetClusterManagedResources } from "services/clusters/useGetClusterManagedResources";

interface ClusterManagedResourcesContainerProps {
  name: string;
}

const ClusterManagedResourcesContainer = ({
  name,
}: ClusterManagedResourcesContainerProps) => {
  const [displayedResources, setDisplayedResources] = useState<
    ManagedResource[]
  >([]);
  const { addToast } = useToaster();
  const { data, isLoading, isError } = useGetClusterManagedResources(name);

  const onSearch = (value: string) => {
    if (value.length < 2) {
      setDisplayedResources(data ?? []);
    } else {
      setDisplayedResources(
        data?.filter((resource) =>
          resource.type.toLocaleLowerCase().includes(value.toLocaleLowerCase())
        ) ?? []
      );
    }
  };
  useEffect(() => {
    if (isError) {
      addToast({
        message: `Error Occured while fetching Managed Ressources for cluster ${name}.`,
        type: "error",
      });
    }
  }, [addToast, isError, name]);

  useEffect(() => {
    if (data) {
      setDisplayedResources(data);
    }
  }, [data]);

  if (isLoading) {
    return <ManagedResourcesSkeleton />;
  }
  if (isError) {
    return <></>;
  }

  return (
    <div className={styles.root}>
      <div className={styles.mainHeading}>
        <div role="heading" aria-level={2}>
          Managed Resources
        </div>

        <div>
          <TextInput
            placeholder="Search"
            onChange={(e: any) => onSearch(e.target.value)}
            className={styles.textInput}
          />
        </div>
      </div>

      {displayedResources.length > 0 ? (
        <ManagedResources managedResources={displayedResources} />
      ) : (
        <div className={styles.noResults}>Nothing is there.</div>
      )}
    </div>
  );
};

export default ClusterManagedResourcesContainer;
