import { CloudProvider } from "types/csp/providers";
import { CDTool } from "types/gitops/gitops";
import * as yup from "yup";

export interface Cluster {
  type: string;
  name: string;
  status: ClusterStatusType;
  message: string;
  age: string;
  cloudProvider: CloudProvider;
  accountName: string;
  username: string;
  repoName: string;
  repoUrl: string;
  gitOpsName: string;
  gitOpsTool?: CDTool;
  version: string;
  nodeType: string;
  nodesize: number;
  tools?: string[];
}

export interface ClusterStatusType {
  phase: StatusType;
  conditions: ConditionsType[];
}

export interface ConditionsType {
  message: string;
  type: string;
  status: string;
}

export interface ManagedResource {
  id: string;
  type: ResourceType;
  age: string;
  link: string;
  ready: string;
  synced: string;
}

// export const clusterTypeEnum = ["multicloud"]
export const clusterTypeEnum = ["kapsule", "multicloud"]// cni ->kapsule:cilium + multicloud:kilo
export const k8sVersionEnum = ["1.30.2","1.28.2", "1.27.6", "1.26.9"]
export const nodesizeEnum = [1, 2, 3, 4, 5, 6, 7, 8]
export const nodeTypeEnum = [{
  name: "PRO2-XS",
  value: "PRO2-XS",
  vCPUs: "4 vCPUs",
  memory: "16 GB",
  network: "700 Mbps"
},{
  name: "PRO2-S",
  value: "PRO2-S",
  vCPUs: "8 vCPUs",
  memory: "32 GB",
  network: "1,5 Gbps"
},{
  name: "PRO2-M",
  value: "PRO2-M",
  vCPUs: "16 vCPUs",
  memory: "64 GB",
  network: "3 Gbps"
},{
  name: "PRO2-L",
  value: "PRO2-L",
  vCPUs: "32 vCPUs",
  memory: "128 GB",
  network: "6 Gbps"
}]

export const nodeTypeEnumAws = [{
  name: "t3.small",
  value: "small",
  vCPUs: "2 vCPUs",
  memory: "2 GB",
  network: "5 Gbps"
},{
  name: "t3.medium",
  value: "medium",
  vCPUs: "2 vCPUs",
  memory: "4 GB",
  network: "5 Gbps"
},{
  name: "t3.large",
  value: "large",
  vCPUs: "2 vCPUs",
  memory: "8 GB",
  network: "5 Gbps"
}]
export const gitopsToolsEnum = ["argocd", "fluxcd"]
export const supportedProvidersEnum = ["aws", "scaleway"]
export const foundationTypeEnum = ["Webserver", "Certmanager","Database", "Orchestration", "Data-platform", "Monitoring", "Registry", "Other"]

export interface ClusterCreationDTO {
  name: string;
  type?: string | undefined;
  accountName: string;
  version: string;
  nodesize: number;
  nodeType?: string | undefined;
  repoName: string;
  gitOpsName: string;
  gitOpsTool: string;
  foundations: (string | undefined)[];
}


export const clusterCreationDSchema = yup.object().shape({
  name: yup.string().required(),
  type: yup.string().oneOf(clusterTypeEnum),
  accountName: yup.string().required(),
  version: yup.string().oneOf(k8sVersionEnum).required(),
  nodesize: yup.number().oneOf(nodesizeEnum).required(),
  nodeType: yup.string().required(),
  repoName: yup.string().required(),
  gitOpsName: yup.string().required(),
  gitOpsTool: yup.string().oneOf(gitopsToolsEnum).required(),
  foundations: yup.array().of(yup.string()).required(),
});

export type StatusType =
  | "RUNNING"
  | "READY"
  | "ERROR"
  | "PENDING"
  | "CREATING"
  | "DELETING"
  | "STABLE"
  | "unknown";

export type ResourceType =
  | "VPC"
  | "Subnet"
  | "RouteTable"
  | "SecurityGroup"
  | "InternetGateway"
  | "EKS";