import { resolveToolUrl } from "components/clusters/utils";
import { IconContext } from "react-icons";
import { StatusTag } from "components/shared/StatusTag/StatusTag";
import { FoundationTemplatesDto } from "types/foundationtemplates/foundationtemplates";

interface StackFoundationTemplateCardIconProps {
  foundationTemplate: FoundationTemplatesDto;
}

export const StackFoundationTemplateCardIcon = ({
  foundationTemplate,
}: StackFoundationTemplateCardIconProps) => {
  const ToolImg = resolveToolUrl(foundationTemplate.name);
  return (
        <div className="w-full p-2 cursor-pointer border-t-0 rounded-r-lg rounded-l-lgpt-2 mt-2">
          <div className="flex">
          <IconContext.Provider value={{size: "3.5rem",color: "" ,className: "mr-3 mb-3 to-blue-900" }}>
            <ToolImg toolName={foundationTemplate.name} className="h-14 w-14 mr-3 mb-3" />
          </IconContext.Provider>
          <div>
            <h2 className="font-semibold text-l">
              {foundationTemplate.name}
            </h2>
            <p >
            <StatusTag status={foundationTemplate.phase == "READY" ? "STABLE" : foundationTemplate.phase} />

            </p>
            <p >
            {foundationTemplate.version}
            </p>
          </div>
          </div>
        </div>
  );
};