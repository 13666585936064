import styles from "./ClustersPage.module.css";
import { PageContent } from "components/sredx-ui/PageContent";
import { Heading } from "components/sredx-ui/Text";
import { ClusterListingContainer } from "containers/clusters/ClusterListingContainer";

const ClustersPage = () => {
  return (
    <PageContent>
      <div className={styles.wrapper}>
        <div className={styles.header_wrapper}>
          <div>
            <Heading>Clusters</Heading>
            <p>
            Quickly set up clusters and streamline your workflow with effective day-2 operations.
            </p>
          </div>
        </div>
        <ClusterListingContainer />
      </div>
    </PageContent>
  );
};

export default ClustersPage;
