import styles from "./FoundationTemplatesDeleteModal.module.css";
import { Card } from "components/sredx-ui/Card";
import { Heading } from "components/sredx-ui/Text";
import { Button } from "components/sredx-ui/Button";
import { FoundationTemplatesDto } from "types/foundationtemplates/foundationtemplates";
import { useDeleteFoundationTemplate } from "services/foundationtemplates/useDeleteFoundationTemplate";

interface FoundationTemplateDeleteModalProps {
  foundationTemplate: FoundationTemplatesDto;
  onClose: () => void;
}

export const FoundationTemplateDeleteModal = ({
  foundationTemplate,
  onClose,
}: FoundationTemplateDeleteModalProps) => {
  const { mutate: deleteCluster } = useDeleteFoundationTemplate();

  const handleDeleteFoundationTemplate = () => {
    if (foundationTemplate) {
      deleteCluster(foundationTemplate.name, {
        onSuccess: () => {
          onClose();
        },
        onError: (error: any) => {
          console.error(error)
        },
      });
    }
  };

  return (
    <Card>
      <div className={styles.wrapper}>
        <div className={styles.content}>
          <Heading level={4}>Confirm Deletion</Heading>
          <p>
            Are you sure you want to delete <strong>{foundationTemplate.name}</strong>?
          </p>
        </div>
        <div className={styles.actions}>
          <Button
            variant="danger"
            onClick={handleDeleteFoundationTemplate}
            className={styles.deleteButton}
          >
            Delete
          </Button>
          <Button ghost onClick={onClose}>
            Cancel
          </Button>
        </div>
      </div>
    </Card>
  );
};
