import { CloudProvider } from "types/csp/providers";
import styles from "./ClusterCardComponent/ClusterCardComponent.module.css";
import { resolveToolUrl } from "./utils";
import { CDTool, GitProvider } from "types/gitops/gitops";


export const LogoText = (
  service: CloudProvider | CDTool  | GitProvider,
  includeText: boolean = true
) => {
  const ImgComponent  = resolveToolUrl(service);

  if (ImgComponent) {

    if (includeText) {
      return (
        <div style={{ display: "flex", alignItems: "center", gap: "4px"}}>
          <ImgComponent className={styles.image}/>
          {service}
        </div>
      );
    } else {
      return  <ImgComponent/>;
    }
  }

  return service;
};
