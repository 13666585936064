import { ClusterSelectFoundationsNewForm } from "./foundations/ClusterSelectFoundationsNewForm";
import { ClusterSelectStacksNewForm } from "./foundations/ClusterSelectStacksNewForm";

interface ClusterCreateSelectFoundationsProps {
  display: boolean;
}
export const ClusterCreateSelectFoundations = ({
  display,
}: ClusterCreateSelectFoundationsProps) => {
  return (
    <div>
      <div className={`flex flex-col gap-4 ${display ? "" : "hidden"}`}>
        <ClusterSelectStacksNewForm />
        <ClusterSelectFoundationsNewForm />
      </div>
    </div>
  );
};