import { forwardRef } from "react";
import { IconProps } from "./types";

export const CloudIcon = forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", title, ...props }, ref) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        {...props}
        ref={ref}
      >
        <title>{title}</title>
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.1"
          d="M15.929 18a4.31 4.31 0 0 0 .56-8.568 5.172 5.172 0 0 0-10.041 1.673 3.448 3.448 0 1 0 0 6.895h9.48Z"
        />
      </svg>
    );
  }
);
