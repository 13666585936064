import ServiceCatalog from "../../components/Landing/features/catalog/ServiceCatalog";
import { Gamification } from "components/Landing/features/gamification/Gamification";
import { FeatureData } from "components/Landing/features/type";


export const FeaturesData : FeatureData[] = [
    {
        title : "Master Your Infrastructure Management for Peak Delivery Lead",
        description:"Clusterdiali enables self service for the most popular engineering tools, giving teams an easy way to build everything about their architecture.",
        illustration: ServiceCatalog,
    },
    {
        title : "Gamification: Fun & Innovation Combined",
        description:"Gamification helps teams innovate in a delightful environment, fostering a sense of ownership and pride as they work on improving service quality.",
        illustration: Gamification,
    }
]