import { forwardRef } from "react";
import clsx from "clsx";
import styles from "./Searchbar.module.css";
import { SearchIcon } from "components/Layout/Icon";

interface SearchbarProps {
  value?: string;
  onChange?: (value: string) => void;
  size?: "small" | "medium";
  autoFocus?: boolean;
  fullWidth?: boolean;
  className?: string;
  placeholder?: string;
}
export const Searchbar = forwardRef<HTMLInputElement, SearchbarProps>(
  (
    {
      value,
      onChange,
      size = "medium",
      autoFocus,
      fullWidth = true,
      className,
      placeholder,
    },
    ref
  ) => {
    const classNames = clsx(
      styles.wrapper,
      styles[size],
      fullWidth && styles.full_width,
      className,
      "nemo-searchbar-wrapper"
    );

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (onChange) {
        onChange(e.target.value);
      }
    };

    return (
      <div className={classNames}>
        <label
          className={clsx(styles.icon, "nemo-searchbar-icon")}
          htmlFor={"search_bar"}
        >
          <SearchIcon />
        </label>
        <input
          autoFocus={autoFocus}
          ref={ref}
          value={value}
          id={"search_bar"}
          onChange={handleChange}
          className={clsx(styles.input, "nemo-searchbar-input")}
          type="text"
          placeholder={placeholder}
        />
      </div>
    );
  }
);
