import React from "react";

import styles from "./ToolCard.module.css";
import {Link} from "react-router-dom";
import {determineStatusLabel, mapStatusToIndicator} from "./utils";
import { resolveToolUrl } from "components/clusters/utils";
import StatusIndicator from "components/sredx-ui/StatusIndicator/StatusIndicator";
import { ClusterDetailsFoundation } from "types/foundations/foundation";

export const ToolCard = (tool: ClusterDetailsFoundation) => {
    const status = mapStatusToIndicator(tool.ready);
    const statusLabel = determineStatusLabel(tool.ready);

    const ToolImg = resolveToolUrl(tool.name);

    return (
        <Link
            to="#"
            //   target="_blank"
            className={styles.wrapper}
        >
            <div className={styles.imageContainer}>
                {ToolImg ? (
                    <ToolImg />
                ) : (
                    <p>{tool.name}</p>
                )}
            </div>
            <div className={styles.infoWrapper}>
                <div className={styles.left}>
                    {tool.name}
                    {/* <div className={styles.badge}>{tool.type}</div> */}
                </div>
                <StatusIndicator label={statusLabel} status={status}/>
            </div>
        </Link>
    )
};
