import { FoundationTemplatesDto } from "types/foundationtemplates/foundationtemplates";
import { foundationTypeEnum } from "types/clusters/clusters";
import { FoundationTemplateCardIcon } from "components/foundationTemplates/FoundationTemplatesCardIcon/FoundationTemplatesCard";
import { FoundationTemplateDeleteModal } from "components/foundationTemplates/FoundationTemplatesDeleteModal";
import { useModal } from "hooks/useModal";
import { IconContext } from "react-icons";
import { resolveToolUrl } from "components/clusters/utils";
import { FoundationTemplatesDetailsModal } from "components/foundationTemplates/FoundationTemplatesDetailsModal/FoundationTemplatesDetailsModal";
import styles from "./FoundationTemplatesListingCardContainer.module.css";


interface FoundationTemplatesListingCardContainerProps {
  foundationTemplates: FoundationTemplatesDto[]
}
export const FoundationTemplatesListingCardContainer = ({foundationTemplates}: FoundationTemplatesListingCardContainerProps) => {
  //hooks
  const { openModal, closeModal } = useModal();

  const handleDeleteFoundationTemplate = (foundationTemplate: FoundationTemplatesDto) => {
    openModal({
      content: (
        <FoundationTemplateDeleteModal
          foundationTemplate={foundationTemplate}
          onClose={closeModal}
        />
      ),
    });
  };
  const handleOpenFoundationTemplateDetails = (foundationTemplate: FoundationTemplatesDto) => {
    openModal({
      content: (
        <FoundationTemplatesDetailsModal
          foundationTemplate={foundationTemplate}
          onClose={closeModal}
        />
      ),
    });
  };
  const filteredAndSortedTypes = foundationTypeEnum
    .map(type => ({
      type,
      count: foundationTemplates.filter(template => template.type === type).length
    }))
    .filter(item => item.count > 0)
    .sort((a, b) => b.count - a.count)
    .map(item => item.type);
  const onClick= (foundationTemplate: FoundationTemplatesDto)=>{
    handleOpenFoundationTemplateDetails(foundationTemplate)
  }
  return (
      <div>
        <div className="grid grid-cols-3 gap-4 w-full mt-5">
          {filteredAndSortedTypes.map((type) =>{
          const ToolImg = resolveToolUrl(type);
          return (
            <div className={styles.wrapper_foundation}  key={type}>
              <div className="flex justify-between border-b-2 text-xl font-semibold">
                {type}
                <IconContext.Provider value={{size: "2.5rem",className: "mr-3 mb-3 to-blue-900" }}>
                  <ToolImg toolName={type} className="h-10 mr-3 mb-3" />
                </IconContext.Provider>
              </div>
              <div className="grid grid-cols-2 w-full">
              {foundationTemplates?.filter((foundationTemplate) =>foundationTemplate.type==type).map((foundationTemplate) => (
                <FoundationTemplateCardIcon
                  key={foundationTemplate.name}
                  foundationTemplate={foundationTemplate}
                  onDelete={handleDeleteFoundationTemplate}
                  onClick={onClick}
                />
              ))}
              </div>
              
            </div>
          )})}
        </div>
    </div>
  );
};
