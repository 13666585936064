
import { AppBreadcrumbs } from "../AppBreadcrumbs";
import { useNavigate } from "react-router-dom";
import { HEADER_USER_ACTIONS } from "./constants";
import { useAuth } from "auth";
import { CogIcon } from "../Icon";
import { HEADER_USER_ACTIONS_TYPE, Header, HeaderAction, HeaderActions, HeaderSpaceBetween, HeaderUser, HeaderUserActionType } from "components/sredx-ui/Header";

interface AppHeaderProps {
  username: string;
}

export const AppHeader = ({ username }: AppHeaderProps) => {
  // Hooks
  const navigate = useNavigate();
  const { signoutRedirect } = useAuth();

  // Event handlers
  const handleClickConfig = () => navigate("/configurations");

  const handleOnAction = (actionType: HeaderUserActionType) => {
    switch (actionType) {
      case HEADER_USER_ACTIONS_TYPE.LOGOUT:
        void signoutRedirect();
        break;
    }
  };

  return (
    <Header>
      <div>
        <AppBreadcrumbs />
      </div>
      <HeaderSpaceBetween />
      <HeaderActions>
        <HeaderAction onClick={handleClickConfig}>
          <CogIcon title={"configuration"} />
        </HeaderAction>
      </HeaderActions>
      <HeaderUser
        name={username}
        actions={HEADER_USER_ACTIONS}
        onAction={handleOnAction}
      />
    </Header>
  );
};
