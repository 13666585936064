import { CspAccountDeleteModal } from "components/cspAccounts/CspAccountDeleteModal";
import styles from "./CspAccountsListingDataTableContainer.module.css";
import { CspAccountsDatatable } from "components/cspAccounts/CspAccountsDatatable";
import { CspAccountsDto } from "types/csp/csp";
import { useModal } from "hooks/useModal";

interface CspAccountsListingCardContainerProps {
  cspAccounts: CspAccountsDto[]
}

export const CspAccountsListingDataTableContainer = ({cspAccounts}: CspAccountsListingCardContainerProps) => {
  const { openModal, closeModal } = useModal();

  // handlers
  const handleDeleteCspAccount = (cspAccount: CspAccountsDto) => {
    openModal({
      content: (
        <CspAccountDeleteModal
          cspAccount={cspAccount}
          onClose={closeModal}
        />
      ),
    });
  };

  //handlers
  const handleClickService = (serviceId: string) => console.log(serviceId);

  return (
    <div className={styles.datatable_wrapper}>
      <CspAccountsDatatable
        cspAccounts={cspAccounts}
        onClickService={handleClickService}
        onDelete={handleDeleteCspAccount}
      />
    </div>
  );
};
