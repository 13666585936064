
import { debounce, isArray } from "lodash";
import { useState } from "react";
import styles from "./CspAccountsListingContainer.module.css";
import { Searchbar } from "components/sredx-ui/Searchbar";
import { ViewToggler } from "components/sredx-ui/ViewToggler";
import { CspAccountsListingDataTableContainer } from "./CspAccountsListingDataTableContainer";
import { CspAccountsListingCardContainer } from "./CspAccountsListingCardContainer";
import { useGetCspAccounts } from "services/csp/useGetCspAccounts";
import { Loader } from "components/sredx-ui";
import { Text } from "components/sredx-ui/Text";
import { Card } from "components/sredx-ui/Card";
import { Empty } from "components/sredx-ui/Empty";

interface CspAccountsListingContainerProps {
  onAddCspAccountClick:  () => void
}
export const CspAccountsListingContainer = ({onAddCspAccountClick}: CspAccountsListingContainerProps) => {
  const {
    data: cspAccounts,
    isFetching,
    isLoading, 
  } = useGetCspAccounts();

  //hooks
  const [view, setView] = useState<"card" | "datatable">("card");

  // Events handler
  const toggleView = () => {
    setView(view === "card" ? "datatable" : "card");
  };

  const handleSearch = (e: string) => {
    console.log(e)
  };
  const debouncedHandleSearch = debounce(handleSearch, 400);

  if (isLoading || isFetching)
    return (
      <div className={styles.loader_wrapper}>
        <Loader size={"large"}  />
        <Text>Loading CSP Accounts...</Text>
      </div>
    );
    if (!cspAccounts || !isArray(cspAccounts)) {
      return (
        <Card className="mt-9">
          <Empty
            message={"No CSP Accounts found for this control plane."}
            actionLabel={"Add new CSP"}
            onActionClick={onAddCspAccountClick}
          />
        </Card>
      );
    }
    const resolveView = () => {
      if (view === "card") {
        return <CspAccountsListingCardContainer  cspAccounts={cspAccounts} />;
      }
      return <CspAccountsListingDataTableContainer cspAccounts={cspAccounts} />;
    };

  return (
    <div className={styles.wrapper}>
      {/*  Search & Filter  */}
      <div className={styles.search_filter_wrapper}>
        <div className={styles.searchbar_wrapper}>
          <Searchbar
            onChange={debouncedHandleSearch}
            placeholder="Search CSP Accounts by name"
          />
        </div>
        <div className={styles.view_toggler}>
          <ViewToggler value={view} onToggle={toggleView} />
        </div>
      </div>
      {resolveView()}
    </div>
  );
  
};
