import styles from "./GitopsAccountDeleteModal.module.css";
import { Card } from "components/sredx-ui/Card";
import { Heading } from "components/sredx-ui/Text";
import { Button } from "components/sredx-ui/Button";
import { GitopsAccountsDto } from "types/gitops/gitops";
import { useDeleteGitopsAccount } from "services/gitops/useDeleteGitopsAccount";

interface GitopsAccountDeleteModalProps {
  gitopsAccount: GitopsAccountsDto;
  onClose: () => void;
}

export const GitopsAccountDeleteModal = ({
  gitopsAccount,
  onClose,
}: GitopsAccountDeleteModalProps) => {
  const { mutate: deleteCluster } = useDeleteGitopsAccount();

  const handleDeleteGitopsAccount = () => {
    if (gitopsAccount) {
      deleteCluster(gitopsAccount.name, {
        onSuccess: () => {
          onClose();
        },
        onError: (error: any) => {
          console.error(error)
        },
      });
    }
  };

  return (
    <Card>
      <div className={styles.wrapper}>
        <div className={styles.content}>
          <Heading level={4}>Confirm Deletion</Heading>
          <p>
            Are you sure you want to delete <strong>{gitopsAccount.name}</strong>?
          </p>
        </div>
        <div className={styles.actions}>
          <Button
            variant="danger"
            onClick={handleDeleteGitopsAccount}
            className={styles.deleteButton}
          >
            Delete
          </Button>
          <Button ghost onClick={onClose} className={styles.cancelButton}>
            Cancel
          </Button>
        </div>
      </div>
    </Card>
  );
};
