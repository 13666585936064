import styles from "./ClusterDetailsToolsContainer.module.css";
import ClusterDetailsToolsSkeleton from "./ClusterDetailsToolsSkeleton";
import { useEffect } from "react";
import { useToaster } from "hooks/useToaster";
import { ToolCard } from "components/clusters/ClusterDetails/ToolCard";
import { useGetClusterFoundations } from "services/clusters/useGetClusterFoundations";
interface ClusterDetailsToolsContainerProps {
  id: string;
}
export const ClusterDetailsToolsContainer = ({
  id,
}: ClusterDetailsToolsContainerProps) => {
  const { data, isLoading, isError } = useGetClusterFoundations(id);
  const { addToast } = useToaster();
  useEffect(() => {
    if (isError) {
      addToast({
        message: `Error Occured while fetching Foundations for cluster ${id}.`,
        type: "error",
      });
    }
  }, [addToast, id, isError]);

  if (isLoading) return <ClusterDetailsToolsSkeleton />;
  if(!Array.isArray(data)) return <div />
  return (
    <div className={styles.wrapper}>
      <h3>Foundations</h3>
      <div className={styles.section}>
        <div className={styles.toolList}>
          {data?.map((foundation, i) => (
            <ToolCard key={i} {...foundation} />
          ))}
        </div>
      </div>
    </div>
  );
};
