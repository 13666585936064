import { FoundationTemplatesDto } from "types/foundationtemplates/foundationtemplates";
import * as yup from "yup";

export interface StacksDto {
    name: string;
    description: string;
    foundationTemplateRefs: FoundationTemplatesDto[];
  }

  export interface StackEditDto {
    foundationTemplateRefs: string[];
  }
  export const stackEditDSchema = yup.object().shape({
    foundationTemplateRefs: yup.array().required().of(yup.string().required()).required(),
  });
  
  export interface StackCreationDto {
    name: string;
    description?: string;
    foundationTemplateRefs: string[];
  }

  export const stackCreationDSchema = yup.object().shape({
    name: yup.string().required(),
    description: yup.string(),
    foundationTemplateRefs: yup.array().required().of(yup.string().required()).required(),
  });