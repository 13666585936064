import clsx from "clsx";
import styles from "./ClusterCardStatusIndicator.module.css";
import { ExclamationCircleIcon } from "@heroicons/react/outline";
import { StatusType } from "types/clusters/clusters";

interface ClusterCardStatusIndicatorProps {
  status: StatusType;
}

const statusStyles = [
  { class: styles.red, label: "error", applyPulse: true  },
  { class: styles.blue, label: "creating", applyPulse: true  },
  { class: styles.green, label: "ready", applyPulse: false  },
  { class: styles.gray, label: "unknown", applyPulse: false  },
  { class: styles.yellow, label: "deleting", applyPulse: true  },
]

function getClassByLabel(label: string) {
  const style = statusStyles.find(style => style.label.toLowerCase() === label.toLowerCase());
  return style ? style : { class: styles.gray, label: "unknown", applyPulse: false  };
}

const renderStatus = (
  status: string,
) => {
  const statusClassObject = getClassByLabel(status);

  switch (status) {
    case "error":
      return <ExclamationCircleIcon className={styles.errorIcon} />;
    default:
      return (
        <div
          className={clsx(
            styles.status_dot,
            statusClassObject.class,
            statusClassObject.applyPulse && "after:animate-ping"
          )}
        />
      );
  }
};
export const ClusterCardStatusIndicator = ({
  status,
}: ClusterCardStatusIndicatorProps) => {
  return (
    <div className={clsx(styles.wrapper)}>
      {renderStatus(status)}
      {status}
    </div>
  );
};
