import styles from "./ControlplanesPage.module.css";
import { PageContent } from "components/sredx-ui/PageContent";
import { Heading } from "components/sredx-ui/Text";
import { ControlplaneMetadataContainer } from "containers/controlplanes/ControlplaneMetadataContainer/ControlplanesMetadataContainer";

const ControlplanesPage = () => {

  return (
    <PageContent>
      <div className={styles.wrapper}>
          <div className={styles.header_wrapper}>
            <div>
              <div>
              <Heading>Controlplanes</Heading>
              <p>
                Manage your Controlplanes in one place.
              </p>
              </div>
            </div>
          </div>
          <ControlplaneMetadataContainer />
          {/* <ControlplaneListingContainer /> */}
        </div>
    </PageContent>
  );
};

export default ControlplanesPage;
