import React from 'react';
import styles from "./Hero.module.css";
import clsx from 'clsx';

export interface HeroProps {
  onGetStarted: () => void;
}

const Hero = ({onGetStarted}: HeroProps) => {

  return (
    <div className={styles.wrapper}>
    <div
      className={clsx(styles.content_wrapper, "container")}
    >
      <h1
        className={styles.title}
      >
       Create a joyful environment for resource provisioning..
      </h1>
        <p className={styles.description}>
        Enables engineering organizations to swiftly provision entire cloud infrastructures using self-service.        </p>
      
      <div>
        <button onClick={onGetStarted} className={clsx("btn","btn_primary")}>
          Get started
        </button>
      </div>
    </div>
  </div>
  )
}

export default Hero