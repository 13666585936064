
import { ServerIcon } from "@heroicons/react/outline";
import {
  ScalewayIcon,
  GcpIcon,
  AzureIcon,
  FluxIcon,
  CertManagerIcon,
  ArgoIcon,
  HelmIcon,
  OpstraceIcon,
  PromotheusIcon,
  GrafanaIcon,
  RedisIcon,
  EnvoyIcon,
  HarborIcon,
  VaultIcon,
  LitmusIcon,
  KubeAppsIcon,
  SonarIcon,
  GithubActionsIcon,
  IstioIcon,
  VeleroIcon,
  KyvernoIcon,
  SealedSecretsIcon,
  KeycloakIcon,
  FeatureFlagIcon,
  OpenCostIcon,
  PostgresqlIcon,
  NginxIcon,
  EksIcon,
  VpcIcon,
  SubnetIcon,
  InternetGatewayIcon,
  SecurityGroupIcon,
  RouteTableIcon,
  KoordinatorIcon,
  ClustersIcon,
  PoolsIcon,
  GithubIcon,
  KustomizeIcon
} from "components/Layout/Icon";
import { ScalewayKapsuleIcon } from "components/Layout/Icon/ScalewayKapsuleIcon";
import { ScalewayMulticloudIcon } from "components/Layout/Icon/ScalewayMulticloudIcon";
import ToolSvgIcon from "components/Layout/Icon/ToolSvgIcon";
import { PiCertificate } from "react-icons/pi";
import { SiAmazonec2, SiAmazoneks, SiApachecassandra, SiApachekafka, SiKubernetes, SiMariadb, SiRedmine, SiSecurityscorecard, SiSpringsecurity } from "react-icons/si";

export const resolveToolUrl = (tool: string) => {
  switch (tool.toLowerCase()) {
    case "scaleway":
      return ScalewayIcon;
    case "multicloud":
      return ScalewayMulticloudIcon;
    case "kapsule":
      return ScalewayKapsuleIcon;
    case "gcp":
      return GcpIcon;
    case "azure":
      return AzureIcon;
    case "flux":
      return FluxIcon;
    case "argo":
      return ArgoIcon;
    case "helm":
      return HelmIcon;
    case "helm-repositories":
      return HelmIcon;
    case "cert-manager":
      return CertManagerIcon;
    case "opstrace":
      return OpstraceIcon;
    case "prometheus":
      return PromotheusIcon;
    case "grafana":
      return GrafanaIcon;
    case "grafana-operator":
      return GrafanaIcon;
    case "redis":
      return RedisIcon;
    case "redis-cluster":
      return RedisIcon;
    case "envoy":
      return EnvoyIcon;
    case "harbor":
      return HarborIcon;
    case "cassandra":
      return SiApachecassandra;
    case "drupal":
      return ToolSvgIcon;
    case "superset":
      return ToolSvgIcon;
    case "minio":
      return ToolSvgIcon;
    case "spark":
      return ToolSvgIcon;
    case "dremio":
      return ToolSvgIcon;
    case "mongodb":
      return ToolSvgIcon;
    case "mongodb-sharded":
      return ToolSvgIcon;
    case "hive-metastore":
      return ToolSvgIcon;
    case "datahub":
      return ToolSvgIcon;
    case "nessie":
      return ToolSvgIcon;
    case "mariadb":
      return SiMariadb;
    case "mariadb-sharded":
      return SiMariadb;
    case "mysql":
      return ToolSvgIcon;
    case "neo4j":
      return ToolSvgIcon;
    case "sonarqube":
      return SonarIcon;
    case "vault":
      return VaultIcon;
    case "litmus":
      return LitmusIcon;
    case "redmine":
      return SiRedmine;
    case "kubeapps":
      return KubeAppsIcon;
    case "sonar":
      return SonarIcon;
    case "github-actions":
      return GithubActionsIcon;
    case "istio":
      return IstioIcon;
    case "velero":
      return VeleroIcon;
    case "kyverno":
      return KyvernoIcon;
    case "kyverno-reporter":
      return KyvernoIcon;
    case "sealed-secrets":
      return SealedSecretsIcon;
    case "keycloak":
      return KeycloakIcon;
    case "feature-flag":
      return FeatureFlagIcon;
    case "opencost":
      return OpenCostIcon;
    case "postgresql":
      return PostgresqlIcon;
    case "nginx-ingress":
      return NginxIcon;
    case "nginx":
      return NginxIcon;
    case "argocd":
      return ArgoIcon;
    case "fluxcd":
      return FluxIcon;
    case "airflow":
      return ToolSvgIcon;
    case "kafka":
      return SiApachekafka;
    case "kafka-connect":
      return SiApachekafka;
    case "kafka-streams":
      return SiApachekafka;
    case "kafka-topics":
      return SiApachekafka;
    case "kafka-connectors":
      return SiApachekafka;
    case "role":
      return SiSpringsecurity;
    case "rolepolicyattachment":
      return SiSecurityscorecard;
    case "cluster":
      return SiAmazoneks;
    case "nodegroup":
      return SiAmazonec2;

    // // managed resources
    case "eks":
      return EksIcon;

    case "vpc":
      return VpcIcon;

    case "subnet":
      return SubnetIcon;

    case "internetgateway":
      return InternetGatewayIcon;

    case "securitygroup":
      return SecurityGroupIcon;

    case "routetable":
      return RouteTableIcon;
    case "koordinator":
      return KoordinatorIcon;

    case "clusters":
      return ClustersIcon;

    case "pools":
      return PoolsIcon;

    // // git providers
    case "github":
      return GithubIcon;
    case "kustomize":
      return KustomizeIcon;
    case "database":
      return ToolSvgIcon;
    case "monitoring":
      return ToolSvgIcon;
    case "data-platform":
      return ToolSvgIcon;
    case "certmanager":
      return PiCertificate;
    case "webserver":
      return ServerIcon;
    case "orchestration":
      return ToolSvgIcon;
    case "registry":
      return ToolSvgIcon;
    case "headlamp":
      return ToolSvgIcon;
    case "kubernetes":
      return ToolSvgIcon;
    case "aws":
      return ToolSvgIcon;
    default:
      return SiKubernetes;
  }
};
