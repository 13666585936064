import ClusterDetailsInfo from "components/clusters/ClusterDetails/ClusterDetailsInfo";
import { useKubeConfig } from "services/kubeconfig/useKubeConfigHook";
import { Cluster } from "types/clusters/clusters";


const ClusterDetailsInfoFetchContainer = ({cluster}: {cluster: Cluster }) => {
  const { isClusterDownloading, handleDownload } = useKubeConfig();

  const handleClusterDownload = () => {
    if (cluster?.name) {
      handleDownload(cluster.name);
    }
  };

  return (
    <ClusterDetailsInfo
      isDownloading={isClusterDownloading(cluster.name)}
      onDownload={handleClusterDownload}
      cluster={cluster}
    />
  );
};

export default ClusterDetailsInfoFetchContainer;
