import { useState } from "react";
import styles from "./styles.module.css";
import { EyeIcon, EyeOffIcon } from "@heroicons/react/outline";
import clsx from "clsx";

export interface TextInputProps {
  label?: string;
  name?: string;
  placeholder?: string;
  error?: string;
  type?: "text" | "textarea" | "number" | "password";
  className?: string;
  endIcon?: React.ReactNode;
  [x: string]: any;
}

export const TextInput = ({
  label,
  name,
  placeholder,
  error,
  type = "text",
  className,
  endIcon,
  ...rest
}: TextInputProps) => {

  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const handleIconClick = () => {
    setIsPasswordVisible(prevIsPasswordVisible => !prevIsPasswordVisible);
  };

  const inputType = type === 'password' && isPasswordVisible ? 'text' : type;

  return (
    <div className={className}>
      {label && <label className={clsx(className, styles.label)}>{label}</label>}
      <div className="relative rounded-md shadow-sm">
        {type === "textarea" ? (
          <textarea
            {...rest}
            className={`${styles.input} ${error && styles.error}`}
            placeholder={placeholder}
          />
        ) : (
          <input
            type={inputType}
            {...rest}
            className={`${styles.input} ${error ? styles.error : ""}`}
            placeholder={placeholder}
          />
        )}
        {type === 'password' && (
          <div className={styles.endIconContainer} onClick={handleIconClick}>
                       {isPasswordVisible ? (
              <EyeIcon className="h-4 w-4" />  
            ) : (
              <EyeOffIcon className="h-4 w-4" />  
            )}
          </div>
        )}
        {endIcon && <div className={styles.endIconContainer}>{endIcon}</div>}
      </div>
      {error && <p className={styles.error}>{error}</p>}
    </div>
  );
};
