
import styles from "./CspAccountsDatatable.module.css";
import { Table, TableBody, TableCell, TableHeader } from "components/sredx-ui/Table";
import { TableHead } from "components/sredx-ui/Table/TableHead/TableHead";
import { TableRow } from "components/sredx-ui/Table/TableRow/TableRow";
import { CloudIcon } from "components/Layout/Icon";
import { ProgressBar } from "components/sredx-ui/ProgressBar";
import { CspAccountsDto } from "types/csp/csp";
import { resolveToolUrl } from "components/clusters/utils";
import { TrashIcon } from "@heroicons/react/outline";

const CSP_ACCOUNT_TABLE_HEADER = [
  "Name",
  "Provider",
  "Phase",
  "Message",
  "Actions"
];

interface CspAccountsDatatableProps {
  isFetching?: boolean;
  cspAccounts?: CspAccountsDto[];
  onClickService: (serviceId: string) => void;
  onDelete?: (gitops: CspAccountsDto) => void;
}

export const CspAccountsDatatable = ({
  cspAccounts = [],
  onClickService,
  isFetching,
  onDelete,
}: CspAccountsDatatableProps) => {

  return (
    <Table>
      <TableHeader>
        {CSP_ACCOUNT_TABLE_HEADER.map((header) => (
          <TableHead key={header}>{header}</TableHead>
        ))}
      </TableHeader>
      <TableBody>
        {isFetching && <ProgressBar position={"absolute"} />}
        {cspAccounts?.map((cspAccount) => {
          const ToolImg = resolveToolUrl(cspAccount.provider);
          return (
          <TableRow
            className={styles.row_wrapper}
            key={cspAccount.name}
            onClick={() => onClickService(cspAccount.name)}
          >
            <TableCell className={styles.first_cell}>
              <div className={styles.service_name_wrapper}>
                <div className={styles.service_icon}>
                  {ToolImg ? <ToolImg className="h-6" />: <CloudIcon />}
                </div>
                <span className={styles.service_name}>{cspAccount.name}</span>
              </div>
            </TableCell>
            <TableCell className={styles.first_cell}>
              <div className={styles.service_name_wrapper}>
                <span className={styles.service_name}>{cspAccount.provider}</span>
              </div>
            </TableCell>
            <TableCell className={styles.first_cell}>
              <div className={styles.service_name_wrapper}>
                <span className={styles.service_name}>{cspAccount.phase}</span>
              </div>
            </TableCell>
            <TableCell className={styles.first_cell}>
              <div className={styles.service_name_wrapper}>
                <span className={styles.service_name}>{cspAccount.message}</span>
              </div>
            </TableCell>
            <TableCell>
              <div className={styles.service_name_wrapper}>
                <span className={styles.service_name}>
                {onDelete && (
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      onDelete(cspAccount);
                    }}
                  >
                    <TrashIcon className="mt-1 h-5 text-slate-900 hover:bg-slate-300 rounded" />
                  </button>
                )}
                </span>
              </div>
            </TableCell>
          </TableRow>
        )})}
      </TableBody>
    </Table>
  );
};
