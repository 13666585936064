import { useState } from "react";
import { useFormContext } from "react-hook-form";
import { resolveToolUrl } from "../../utils";
import { k8sVersionEnum } from "types/clusters/clusters";

interface ClusterSelectKubernetesVersionProps {
  errors?: any;
}

export const ClusterSelectKubernetesVersion = ({
  errors,
}: ClusterSelectKubernetesVersionProps) => {

  const [selected, setSelected] = useState<string>();
  const methods = useFormContext();
  // please add errors handling
  const handleSelect = (value: string) => {
    setSelected(value);
    methods.setValue("version", value);
    return;
  };
  
  return (
    <div className="flex flex-col gap-1 mt-2 mb-7">
      <div className="text-lg mb-2">
        Kubernetes Cluster Version
      </div>
      <div className="grid grid-cols-2 lg:grid-cols-4 gap-4">
        {k8sVersionEnum.map((item, i) => {
          const ToolImg = resolveToolUrl("kubernetes");
            return ToolImg ? (
              <div
                className={`border border-gray-400 text-gray-700 items-center flex px-4 p-2 gap-4 cursor-pointer w-full ${
                  selected == item && " border-cyan-600 bg-cyan-50 text-cyan-700"
                }`}
                onClick={() => handleSelect(item)}
              >
                <ToolImg toolName="kubernetes" className="h-4" />
                <div>Version {item}</div>                
              </div>
            ) : null;
        }).filter(Boolean)}
      </div>
      <span className="text-xs text-red-800">
        {errors?.version?.message}
      </span>
      
    </div>
  );
};