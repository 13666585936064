
import styles from "./GitopsAccountsDatatable.module.css";
import { Table, TableBody, TableCell, TableHeader } from "components/sredx-ui/Table";
import { TableHead } from "components/sredx-ui/Table/TableHead/TableHead";
import { TableRow } from "components/sredx-ui/Table/TableRow/TableRow";
import { ProgressBar } from "components/sredx-ui/ProgressBar";
import { GitopsAccountsDto } from "types/gitops/gitops";
import { resolveToolUrl } from "components/clusters/utils";
import { CodeIcon, TrashIcon } from "@heroicons/react/outline";

const GITOPS_ACCOUNT_TABLE_HEADER = [
  "Name",
  "Owner",
  "Phase",
  "Message",
  "Actions"
];

interface GitopsAccountsDatatableProps {
  isFetching?: boolean;
  gitopsAccounts?: GitopsAccountsDto[];
  onDelete?: (gitops: GitopsAccountsDto) => void;
}

export const GitopsAccountsDatatable = ({
  gitopsAccounts = [],
  isFetching,
  onDelete
}: GitopsAccountsDatatableProps) => {

  return (
    <Table>
      <TableHeader>
        {GITOPS_ACCOUNT_TABLE_HEADER.map((header) => (
          <TableHead key={header}>{header}</TableHead>
        ))}
      </TableHeader>
      <TableBody>
        {isFetching && <ProgressBar position={"absolute"} />}
        {gitopsAccounts?.map((gitopsAccount) => {
          const ToolImg = resolveToolUrl("github");
          return (
          <TableRow
            className={styles.row_wrapper}
            key={gitopsAccount.name}
          >
            <TableCell className={styles.first_cell}>
              <div className={styles.service_name_wrapper}>
                <div className={styles.service_icon}>
                {ToolImg ? <ToolImg className="h-6" />: <CodeIcon />}
                </div>
                <span className={styles.service_name}>{gitopsAccount.name}</span>
              </div>
            </TableCell>
            <TableCell className={styles.first_cell}>
              <div className={styles.service_name_wrapper}>
                <span className={styles.service_name}>{gitopsAccount.owner}</span>
              </div>
            </TableCell>
            <TableCell className={styles.first_cell}>
              <div className={styles.service_name_wrapper}>
                <span className={styles.service_name}>{gitopsAccount.phase}</span>
              </div>
            </TableCell>
            <TableCell className={styles.first_cell}>
              <div className={styles.service_name_wrapper}>
                <span className={styles.service_name}>{gitopsAccount.message}</span>
              </div>
            </TableCell>
            <TableCell>
              <div className={styles.service_name_wrapper}>
                <span className={styles.service_name}>
                {onDelete && (
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      onDelete(gitopsAccount);
                    }}
                  >
                    <TrashIcon className="mt-1 h-5 text-slate-900 hover:bg-slate-300 rounded" />
                  </button>
                )}
                </span>
              </div>
            </TableCell>
          </TableRow>
        )})}
      </TableBody>
    </Table>
  );
};
