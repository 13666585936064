// import styles from "./ControlplanesMetadataContainer.module.css";

import { StatusTag } from "components/shared/StatusTag/StatusTag";
import { Button } from "components/sredx-ui/Button";
import { ListingCard } from "components/sredx-ui/ListingCard";
import { LoadingOverlay } from "components/sredx-ui/LoadingOverlay/LoadingOverlay";
import { ProgressBar } from "components/sredx-ui/ProgressBar";
import { useGetControlplaneMetadata } from "services/controlplanes/useGetControlplaneMetadata";
import { getColorByPercentage } from "./utils";
import { useControlplaneKubeConfig } from "services/controlplanes/kubeconfig/useKubeConfigHook";
import { MouseEventHandler } from "react";

export const ControlplaneMetadataContainer = () => {
  const { data: controlplaneMetadata, isLoading } = useGetControlplaneMetadata();
  // const isButtonLoading = isClusterDownloading();
  const { handleDownload } = useControlplaneKubeConfig();

  const handleOnClick: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    if(handleDownload){
      handleDownload();
    }
  };

  if(isLoading || controlplaneMetadata == undefined){
    return <LoadingOverlay message="We are loading your controlplane details"/>
  }
  return (
    <div>
          <ListingCard>
            <div className="flex justify-between">
              <h3 className="font-bold text-lg">Provider Information</h3>
                <Button 
                variant="primary"
                onClick={handleOnClick}
                // isLoading={isButtonLoading}
                // disabled={isButtonLoading}
              >
                Download Kubeconfig
              </Button>
            </div>
              
              <div className="grid grid-cols-3 gap-x-20 mt-3 mx-3">
                <div className="flex justify-between pr-10">
                  <span className="font-semibold ">Kubelet Version:</span>
                  <span className="ml-10 ">{controlplaneMetadata.kubeletVersion}</span>
                </div>
                <div className="flex justify-between pr-10">
                  <span className="font-semibold ">Creation Timestamp:</span>
                  <span className="ml-10 ">{controlplaneMetadata.creationTimestamp}</span>
                </div>
                <div className="flex justify-between pr-10">
                  <span className="font-semibold ">OS:</span>
                  <span className="ml-10 ">{controlplaneMetadata.os}</span>
                </div>
                <div className="flex justify-between pr-10">
                  <span className="font-semibold ">OS Image:</span>
                  <span className="ml-10 ">{controlplaneMetadata.osImage}</span>
                </div>
              </div>
            </ListingCard>
        
          <h2 className="text-xl font-bold mt-8 mb-4">Node Status</h2>
          <div className="grid grid-cols-2 gap-4">
            {
              controlplaneMetadata.nodes.map((node)=>
                <ListingCard>
              <div className="flex justify-between">
                <h3 className="font-bold">Node:  {node.name}</h3>
                <StatusTag status={node.status.toUpperCase()} />
              </div>
              
              <div className="flex flex-col text-gray-800 mx-3">
                <div className="flex justify-between">
                  <span>Instance Type:</span>
                  <span>{node.instanceType}</span>
                </div>
                <div className="flex justify-between">
                  <span>Zone:</span>
                  <span>{node.zone}</span>
                </div>
                <div className="flex justify-between">
                  <span>Hostname:</span>
                  <span>{node.hostname}</span>
                </div>
                <div className="flex justify-between">
                  <span>ExternalIP:</span>
                  <span>{node.externalIp}</span>
                </div>
              </div>

              <h3 className="font-semibold">Status</h3>
              
              <ul className="list-disc pl-5">
                <li  className="mb-5">
                  PID Pressure: {node.cpu.pressure ? "True": "False"}
                <div className="mx-10">
                    <ProgressBar color={getColorByPercentage(node.cpu.percentage)} percent={node.cpu.percentage} label={`${node.cpu.allocatable} / ${node.cpu.capacity}`} infinite={false}/>
                  </div>
                </li>
                <li className="mb-5">
                  Memory Pressure: {node.memory.pressure ? "True": "False"}
                  <div className="mx-10">
                    <ProgressBar color={getColorByPercentage(node.memory.percentage)} percent={node.memory.percentage}  label={`${node.memory.allocatable} / ${node.memory.capacity}`} infinite={false}/>
                  </div>
                </li>
                <li  className="mb-5">
                  Disk Pressure: {node.storage.pressure ? "True": "False"}
                  <div className="mx-10">
                    <ProgressBar color={getColorByPercentage(node.storage.percentage)} percent={node.storage.percentage}  label={`${node.storage.allocatable} / ${node.storage.capacity}`} infinite={false}/>
                  </div>
                </li>
                
              </ul>
            </ListingCard>
              )
            }
            </div>
          </div>
  );
};
