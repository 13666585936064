
import LoadingComponent from "components/basic/LoadingComponent/LoadingComponent";
import { CounterCard } from "components/home/CounterCard";
import { useGetDashboardCounters } from "services/dashboard/useGetDashboardCounters";


export const HomeCounterContainer = () => {
    const {
        data: counters,
        isFetching,
        isLoading, 
      } = useGetDashboardCounters();
  if(isFetching || isLoading){
    return <LoadingComponent />
  }
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-10 mt-5">
        {
            counters?.map(count =>
                <CounterCard key={count.resource} count={count.count} title={count.resource}/>
            )
        }
    </div>
  );
  
};
