import { TextInput } from "components/sredx-ui/Forms/TextInput";
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { resolveToolUrl } from "../../utils";
import { useGetFoundationTemplates } from "services/foundationtemplates/useGetFoundationTemplates";
import { Text } from "components/sredx-ui/Text";
import { FoundationTemplatesDto } from "types/foundationtemplates/foundationtemplates";
import styles from "./ClusterSelectFoundationsNewForm.module.css";
import { Loader } from "components/sredx-ui";


export const ClusterSelectFoundationsNewForm = () => {
  const {
    data: foundationTemplates,
    isFetching,
    isLoading, 
  } = useGetFoundationTemplates();

  const [selected, setSelected] = useState<string[]>([]);
  const [displayedTools, setDisplayedTools] = useState<FoundationTemplatesDto[]>(foundationTemplates ? foundationTemplates : []);
  const methods = useFormContext();
  useEffect(() => {
    if (!isFetching && !isLoading && foundationTemplates) {
      setDisplayedTools(foundationTemplates);
    }
  }, [isFetching, isLoading, foundationTemplates]);

  if(isFetching || isLoading || !foundationTemplates){
    return (
      <div className={styles.loader_wrapper}>
        <Loader size={"large"}  />
        <Text>Loading foundation templates...</Text>
      </div>
    );
  }

  const handleSearch = (value: string) => {
      let filteredFoundations = foundationTemplates?.filter((tool) =>
        tool.name.toLocaleLowerCase().includes(value.toLowerCase())
      )
      setDisplayedTools(filteredFoundations ? filteredFoundations : []);
    
  };

  const handleSelect = (value: string) => {
    // Get the current values of "foundations" from the form
    const currentValues = methods.getValues("foundations") || [];
    if (currentValues.includes(value)) {
      setSelected(selected.filter((item: any) => item !== value));
      const updatedValues = currentValues.filter((item: any) => item !== value);
      methods.setValue("foundations", updatedValues);
      return;
    }
      setSelected([...selected, value]);
      // If the value is not in the list, add it
      const updatedValues = [...currentValues, value];
      methods.setValue("foundations", updatedValues);
    
  
    return;
  };
  
  if (isLoading || isFetching || !foundationTemplates )
    return (
      <div>
        <Text>Loading Foundation Templates...</Text>
      </div>
    );
    if(!Array.isArray(foundationTemplates)){
      return (
        <div>
          <Text>You have no foundation templates...</Text>
        </div>
      );
    }

  return (
    <div className={`flex flex-col gap-4`}>
      
      <div>
        <div className="text-lg text-gray-700">
          Select your starting foundations
        </div>
        <TextInput
          placeholder="Search for your foundation"
          onChange={(e: any) => handleSearch(e.target.value)}
        />
      </div>
      <div className="grid grid-cols-3 gap-4">
        {displayedTools.filter(item => item.phase=="READY").map((item, i) => {
          const ToolImg = resolveToolUrl(item.name);
            return ToolImg ? (
              <div
                className={`border flex px-4 p-2 items-center gap-4 cursor-pointer ${
                  selected.includes(item.name) && " border-sky-500 text-sky-700 bg-gray-50"
                }`}
                onClick={() => handleSelect(item.name)}
              >
                <ToolImg toolName={item.chart} className="h-10" />
                <div>{item.name}</div>
              </div>
            ) : null;
        }).filter(Boolean)}
      </div>
    </div>
  );
};