import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import React from "react";
import { ROUTES } from "routes/routes";
import { ROUTE } from "./types";
import { PrivateRoute } from "./PrivateRoute";

const renderRoutes = (routes: ROUTE[]) => {
  return routes.map((route) => {
    const { path, element, children, isPrivate } = route;
    const firstChild = children && children.length > 0 && children[0];

    const Element = isPrivate ? <PrivateRoute component={element} /> : element;
    return (
      <Route key={route.path} path={path} element={Element}>
        {firstChild && (
          <Route
            key={firstChild.path}
            index={true}
            element={
              firstChild.element || <Navigate to={firstChild.path || ""} />
            }
          />
        )}
        {children && renderRoutes(children)}
      </Route>
    );
  });
};

export const AppRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>{renderRoutes(ROUTES)}</Routes>
    </BrowserRouter>
  );
};
