
import { API_ENDPOINTS } from "config/apiPaths";
import { QUERY_KEYS } from "config/queryKeys";
import { useQuery } from "react-query";
import { ClientApi } from "services/clientApi";
import { CspAccountsDto } from "types/csp/csp";


export const useGetCspAccounts = () => {
  return useQuery<CspAccountsDto[]>({
    queryKey: [QUERY_KEYS.cspAccounts],
    queryFn: async () =>
      await ClientApi.get(API_ENDPOINTS.getCspAccounts()),
    retry: 5,
  retryDelay: 1000,
  });
};