
import { API_ENDPOINTS } from "config/apiPaths";
import { QUERY_KEYS } from "config/queryKeys";
import { useQuery } from "react-query";
import { ClientApi } from "services/clientApi";
import { StacksDto } from "types/stacks/stacks";


export const useGetStacks = () => {
  return useQuery<StacksDto[]>({
    queryKey: [QUERY_KEYS.stacks],
    queryFn: async () =>
      await ClientApi.get(API_ENDPOINTS.getStacks()),
    retry: 5,
  retryDelay: 1000,
  });
};