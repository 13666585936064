import { forwardRef } from "react";
import { IconProps } from "./types";

export const LitmusIcon = forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", title, ...props }, ref) => {
    return (
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        ref={ref}
        viewBox="0 0 200 200"
        enableBackground="new 0 0 200 200"
      >
        <title>{title}</title>
        <path
          fill="#858CDD"
          opacity="1.000000"
          stroke="none"
          d="M172.000000,201.000000 C123.979103,201.000000 75.958206,201.000000 27.834599,200.793945 
	C20.622807,195.207748 19.583967,187.881226 19.624214,179.419037 
	C19.897598,121.939781 19.764828,64.458603 19.776966,6.978043 
	C19.777388,4.985346 19.922388,2.992680 20.000000,1.000000 
	C73.020897,1.000000 126.041786,1.000000 179.530685,1.468657 
	C179.998627,60.962196 179.927994,119.987259 180.077560,179.011765 
	C180.099030,187.482361 179.598770,195.319260 171.507690,200.329880 
	C171.671799,200.553253 171.835907,200.776627 172.000000,201.000000 M44.921497,77.605995 
	C44.921497,110.365692 44.921497,143.125397 44.921497,176.241608 
	C53.163513,176.241608 60.886620,176.241608 69.012344,176.241608 
	C69.012344,142.782791 69.012344,109.691162 69.019325,75.715469 
	C69.045189,60.430046 69.007500,45.144047 69.145973,29.859646 
	C69.176826,26.454176 67.996864,25.021629 64.528084,25.140955 
	C59.715443,25.306509 54.884560,25.351906 50.076939,25.123558 
	C46.222561,24.940489 44.809971,26.313213 44.859360,30.269604 
	C45.052223,45.719135 44.972633,61.172062 44.921497,77.605995 M81.434647,163.511185 
	C81.439148,166.672791 81.103493,169.889740 81.604576,172.970581 
	C81.825287,174.327591 83.737144,176.449020 84.897728,176.459885 
	C107.027008,176.667389 129.159271,176.666443 151.288544,176.458008 
	C152.467377,176.446915 154.541580,174.291641 154.633423,173.021347 
	C155.016388,167.723953 154.585434,162.371536 154.874924,157.062302 
	C155.088303,153.148849 153.798355,151.927368 149.821182,151.960938 
	C128.523346,152.140762 107.223190,152.039948 85.923859,152.061172 
	C84.640320,152.062454 83.356987,152.280029 81.435623,152.455078 
	C81.435623,155.958817 81.435623,159.245651 81.434647,163.511185 
z"
        />
        <path
          fill="#FEFEFF"
          opacity="1.000000"
          stroke="none"
          d="
M69.012344,76.599525 
	C69.012344,109.691162 69.012344,142.782791 69.012344,176.241608 
	C60.886620,176.241608 53.163513,176.241608 44.921497,176.241608 
	C44.921497,143.125397 44.921497,110.365692 45.425720,77.088051 
	C53.624081,76.579910 61.318211,76.589722 69.012344,76.599525 
z"
        />
        <path
          fill="#FCFCFE"
          opacity="1.000000"
          stroke="none"
          d="
M81.435135,163.021851 
	C81.435623,159.245651 81.435623,155.958817 81.435623,152.455078 
	C83.356987,152.280029 84.640320,152.062454 85.923859,152.061172 
	C107.223190,152.039948 128.523346,152.140762 149.821182,151.960938 
	C153.798355,151.927368 155.088303,153.148849 154.874924,157.062302 
	C154.585434,162.371536 155.016388,167.723953 154.633423,173.021347 
	C154.541580,174.291641 152.467377,176.446915 151.288544,176.458008 
	C129.159271,176.666443 107.027008,176.667389 84.897728,176.459885 
	C83.737144,176.449020 81.825287,174.327591 81.604576,172.970581 
	C81.103493,169.889740 81.439148,166.672791 81.435135,163.021851 
z"
        />
        <path
          fill="#5B45BA"
          opacity="1.000000"
          stroke="none"
          d="
M69.015839,76.157501 
	C61.318211,76.589722 53.624081,76.579910 45.462143,76.596916 
	C44.972633,61.172062 45.052223,45.719135 44.859360,30.269604 
	C44.809971,26.313213 46.222561,24.940489 50.076939,25.123558 
	C54.884560,25.351906 59.715443,25.306509 64.528084,25.140955 
	C67.996864,25.021629 69.176826,26.454176 69.145973,29.859646 
	C69.007500,45.144047 69.045189,60.430046 69.015839,76.157501 
z"
        />
      </svg>
    );
  }
);
