import { Button } from "components/sredx-ui/Button";
import styles from "./ClusterDeleteModal.module.css";
import { Card } from "components/sredx-ui/Card";
import { Heading } from "components/sredx-ui/Text";

interface ClusterDeleteModalProps {
  closeModal: () => void;
  handleDeleteControlplane: () => void;
}
export const ClusterDeleteModal = ({closeModal, handleDeleteControlplane}:ClusterDeleteModalProps) => {
    return (
      <Card>
      <div className={styles.wrapper}>
        <div className={styles.content}>
          <Heading level={4}>Confirm Deletion</Heading>
          <p>
            Are you sure you want to delete <strong>this cluster</strong>?
          </p>
        </div>
        <div className={styles.actions}>
          <Button
            variant="danger"
            onClick={handleDeleteControlplane}
            className={styles.deleteButton}
          >
            Delete
          </Button>
          <Button ghost onClick={closeModal}>
            Cancel
          </Button>
        </div>
      </div>
    </Card>
    );
  };
  