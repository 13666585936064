import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { useToaster } from "hooks/useToaster";
import { LoadingOverlay } from "components/sredx-ui/LoadingOverlay/LoadingOverlay";
import { Button } from "components/sredx-ui/Button";
import { useCreateCluster } from "services/clusters/useCreateCluster";
import { ClusterCreationDTO, clusterCreationDSchema } from "types/clusters/clusters";
import { useGetGitopsAccounts } from "services/gitops/useGetGitopsAccounts";
import { useGetCspAccounts } from "services/csp/useGetCspAccounts";
import { useState } from "react";
import { ClusterCreateNewForm } from "components/clusters/ClusterCreateNewForm/ClusterCreateNewForm";
import { ClusterCreateSelectFoundations } from "components/clusters/ClusterCreateNewForm/ClusterCreateSelectFoundations";
import { useNavigate } from "react-router-dom";
import { CLUSTERS_PAGE } from "routes/paths";
import { ProgressStepper } from "components/basic/ProgressStepper";
import { clusterCreationSteps } from "components/basic/ProgressStepper/types";

const formSteps = {
  CLUSTER_INFO: "CLUSTER_INFO",
  FOUNDATIONS: "FOUNDATIONS",
};

export const ClusterCreatePageContainer = () => {
  const [formStep, setFormStep] = useState(formSteps.CLUSTER_INFO);
  const { mutate, isLoading } = useCreateCluster();
  const { addToast } = useToaster();
  const { data: cspAccounts = [] } = useGetCspAccounts();
  const { data: gitopsAccounts = [] } = useGetGitopsAccounts();
  const navigate = useNavigate(); 


  const methods = useForm({
    mode: "onChange",
    resolver: yupResolver(clusterCreationDSchema),
  });

  const handleStepToggle = () => {
    if (formStep === formSteps.CLUSTER_INFO) {
      setFormStep(formSteps.FOUNDATIONS);
    }
    if (formStep === formSteps.FOUNDATIONS) {
      setFormStep(formSteps.CLUSTER_INFO);
    }
  };

  const handleClickCreate = () => {
    const clusterCreate = (data: ClusterCreationDTO) => {
      mutate(data, {
        onSuccess: () => {
          navigate(CLUSTERS_PAGE);
          
          addToast({
            message: `Cluster ${data.name} created successfully.`,
            type: "success",
          });
        },
        onError: (error: any) => {
          addToast({
            message: `Error occured while creating cluster ${data.name}. ${error.message}`,
            type: "error",
          });
        },
      });
    };
    methods.handleSubmit((data) => {
      clusterCreate(data)
    })();
    if (!methods.formState.isValid) {
        setFormStep(formSteps.CLUSTER_INFO);
        return;
      }
  };


  const renderFormActions = () => {
    return (
      <div className="flex justify-end gap-4 mt-6">
        {formStep === formSteps.CLUSTER_INFO && (
          <>
            <button>Cancel</button>
            <Button onClick={handleStepToggle}>
              Next
            </Button>
          </>
        )}
        {formStep === formSteps.FOUNDATIONS && (
          <>
            <button className="btn btn-secondary" onClick={handleStepToggle}>
              Back
            </button>
            <Button  onClick={handleClickCreate}>
              Confirm
            </Button>
          </>
        )}
      </div>
    );
  };

  return (
    <div className="relative lg:mx-80 md:mx-10">
      <div className="w-1/2 flex text-center mx-48">
        <ProgressStepper
          steps={clusterCreationSteps}
          currentStep={formSteps.CLUSTER_INFO === formStep ? 1 : 2}
          renderLabels
        />
      </div>
      
      {isLoading && <LoadingOverlay message="Creating Cluster" />}
      <FormProvider {...methods}>
        <ClusterCreateNewForm
          errors={methods.formState.errors}
          cloudAccounts={cspAccounts}
          gitopsAccounts={gitopsAccounts}
          display={formStep === formSteps.CLUSTER_INFO}
          selectedAccount={methods.watch("accountName")} 
        />
        <ClusterCreateSelectFoundations display={formStep === formSteps.FOUNDATIONS} />
      </FormProvider>
      {renderFormActions()}
      </div>
  );
};
