import styles from "./StackDetailsModal.module.css";
import { Card } from "components/sredx-ui/Card";
import { Button } from "components/sredx-ui/Button";
import { resolveToolUrl } from "components/clusters/utils";
import { stackEditDSchema, StackEditDto, StacksDto } from "types/stacks/stacks";
import { useDeleteStack } from "services/stacks/useDeleteStack";
import { useState } from "react";
import { StackDescriptionDetailsModal } from "./StackDescriptionDetailsModal";
import { StackFoundationsDetailsModal } from "./StackFoundationsDetailsModal";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEditStack } from "services/stacks/useEditStack";
import { useToaster } from "hooks/useToaster";

interface StackDetailsModalProps {
  stack: StacksDto;
  onClose: () => void;
}
const formSteps = {
  STACK_INFO: "STACK_INFO",
  FOUNDATIONS: "FOUNDATIONS",
};
export const StackDetailsModal = ({
  stack,
  onClose,
}: StackDetailsModalProps) => {
  const [formStep, setFormStep] = useState(formSteps.STACK_INFO);
  const { mutate } = useEditStack(stack.name);
  const { mutate: deleteStack } = useDeleteStack();
  const { addToast } = useToaster();

  const methods = useForm({
    mode: "onChange",
    resolver: yupResolver(stackEditDSchema),
  });

  const handleFoundationsEdit = () => {
    const stackEdit = (data: StackEditDto) => {
      mutate(data, {
        onSuccess: () => {
          addToast({
            message: `Stack updated successfully.`,
            type: "success",
          });
          onClose();
        },
        onError: (error: any) => {
          addToast({
            message: `Error occured while updating stack. ${error.message}`,
            type: "error",
          });
        },
      });
    };
    methods.handleSubmit((data) => {
      stackEdit(data)
    })();
    if (!methods.formState.isValid) {
        setFormStep(formSteps.STACK_INFO);
        return;
      }
  };
  const handleDeleteFoundationTemplate = () => {
    if (stack) {
      deleteStack(stack.name, {
        onSuccess: () => {
          onClose();
        },
        onError: (error: any) => {
          console.error(error)
        },
      });
    }
  };
  const handleStepToggle = () => {
    if (formStep === formSteps.STACK_INFO) {
      setFormStep(formSteps.FOUNDATIONS);
    }
    if (formStep === formSteps.FOUNDATIONS) {
      setFormStep(formSteps.STACK_INFO);
    }
  };
  const renderFormActions = () => {
    return (
      <div className="flex justify-end gap-4 mt-6">
        {formStep === formSteps.STACK_INFO && (
          <>
            <button className="btn btn-secondary" onClick={onClose}>Close</button>
            <Button onClick={handleStepToggle}>
              Edit Foundations
            </Button>
            <Button
            variant="danger"
            onClick={handleDeleteFoundationTemplate}
            className={styles.deleteButton}
          >
            Delete
          </Button>
          </>
        )}
        {formStep === formSteps.FOUNDATIONS && (
          <>
            <button className="btn btn-secondary" onClick={handleStepToggle}>
              Back
            </button>
            <Button  onClick={handleFoundationsEdit}>
              Edit
            </Button>
          </>
        )}
      </div>
    );
  };
  const ToolImg = resolveToolUrl(stack.name);
  return (
    <Card>
      <div className={styles.wrapper}>
        <div className={styles.content}>
      <div className="flex justify-between">
        <div className={styles.head}>
          <h2 className="text-lg font-bold flex uppercase text-gray-800">
           <ToolImg className="h-7 mr-3 mb-3" />
            {stack.name}
            </h2>
        </div>
      </div>
      {/* Body Section */}
      <FormProvider {...methods}>
        <StackDescriptionDetailsModal display={formStep === formSteps.STACK_INFO} stack={stack} />
        <StackFoundationsDetailsModal display={formStep === formSteps.FOUNDATIONS} stack={stack} />
        </FormProvider>
        </div>
        <div className={styles.actions}>
        {renderFormActions()}
        </div>
      </div>
    </Card>
  );
};
