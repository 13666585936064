import { useState } from "react";
import { useFormContext } from "react-hook-form";
import { resolveToolUrl } from "../../utils";

interface ClusterSelectGitopsAccountProps {
  errors?: any;
  gitopsAccounts: { name: string; owner: string }[];
}

export const ClusterSelectGitopsAccount = ({
  errors,
  gitopsAccounts,
}: ClusterSelectGitopsAccountProps) => {

  const [selected, setSelected] = useState<string>();
  const methods = useFormContext();

  // please add errors handling
  const handleSelect = (value: string) => {
      setSelected(value)
      methods.setValue("gitOpsName", value)
      
      return;
    }
  return (
    <div className={`flex flex-col gap-1 mt-2 mb-7`}>
      <div className="text-lg mb-2">
        Gitops Account
      </div>
      <div className="grid grid-cols-4 gap-4">
        {gitopsAccounts.map((item, i) => {
          const ToolImg = resolveToolUrl("github");
            return ToolImg ? (
              <div
                className={`border border-gray-400 text-gray-700 flex px-4 p-2 items-center gap-4 cursor-pointer ${
                  selected == item.name && "border-cyan-600 bg-cyan-50 text-cyan-700"
                }`}
                onClick={() => handleSelect(item.name)}
              >
                <ToolImg toolName={"github"} className="h-4" />
                <div className="flex flex-col">
                    <label className="">{item.name}</label>
                    <span className="text-xs font-light">Owner:  {item.owner}</span>
                  </div>
              </div>
            ) : null;
        }).filter(Boolean)}
      </div> 
      <span className="text-xs text-red-800">
        {errors?.accountName?.message}
      </span>
      
    </div>
  );
};