import { resolveToolUrl } from "components/clusters/utils";
import { Card, CardBody } from "components/sredx-ui/Card";
import { MouseEventHandler } from "react";
import { IconContext } from "react-icons";
import { StacksDto } from "types/stacks/stacks";
import { StackFoundationTemplateCardIcon } from "../StackCardIcon/StackCard";
import { StackDetailsModal } from "../StackDetailsModal/StackDetailsModal";
import { useModal } from "hooks/useModal";

interface StackCardComponentProps {
  stack: StacksDto;
}

export const StackCardComponent = ({
  stack,
}: StackCardComponentProps) => {
  const { openModal, closeModal } = useModal();

  const handleClickStack = (stack: StacksDto) => {
    openModal({
      content: (
        <StackDetailsModal
          stack={stack}
          onClose={closeModal}
        />
      ),
    });
  };

  const handleOnClick: MouseEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault();
    if (handleClickStack) {
      handleClickStack(stack);
    }
  };

  const ToolImg = resolveToolUrl(stack.name);
    return (
      <Card clickable onClick={handleOnClick} >
        <CardBody>
      <div key={stack.name} className="my-5">
        <div className="flex justify-between border-b-2 text-xl font-semibold">
          {stack.name}
          <IconContext.Provider value={{size: "2.5rem",className: "mr-3 mb-3 to-blue-900" }}>
            <ToolImg toolName={stack.name} className="h-10 mr-3 mb-3" />
          </IconContext.Provider>
        </div>
        <div className="grid grid-cols-2 w-full">
        {stack.foundationTemplateRefs?.map((foundationTemplate) => (

        <StackFoundationTemplateCardIcon
          key={foundationTemplate.name}
          foundationTemplate={foundationTemplate}
        />
        ))}
        </div>
        
      </div>
      </CardBody>
      </Card>
    )
};

