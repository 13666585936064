
import { API_ENDPOINTS } from "config/apiPaths";
import { QUERY_KEYS } from "config/queryKeys";
import { useQuery } from "react-query";
import { ClientApi } from "services/clientApi";
import { KubeConfigResponseDto } from "types/kubeconfig";

interface UseGetKubeConfigParams {
  clusterName: string | null;
}

export const useGetKubeConfig = ({ clusterName }: UseGetKubeConfigParams) => {
  return useQuery<KubeConfigResponseDto>({
    queryKey: [QUERY_KEYS.kubeConfig, clusterName],
    queryFn: async () =>
      await ClientApi.get(API_ENDPOINTS.getKubeConfig(clusterName!)),
    enabled: !!clusterName,
    retry: false,
  });
};
