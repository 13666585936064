import { TextInput } from "components/sredx-ui/Forms/TextInput";
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { Text } from "components/sredx-ui/Text";
import styles from "./ClusterSelectStacksNewForm.module.css";
import { Loader } from "components/sredx-ui";
import { useGetStacks } from "services/stacks/useGetStacks";
import { StacksDto } from "types/stacks/stacks";
import { StacksFoundationsCards } from "./StacksFoundationsCards";


export const ClusterSelectStacksNewForm = () => {
  const {
    data: stacks,
    isFetching,
    isLoading, 
  } = useGetStacks();

  const [selected, setSelected] = useState<string[]>([]);
  const [displayedTools, setDisplayedTools] = useState<StacksDto[]>(stacks ? stacks : []);
  const methods = useFormContext();
  useEffect(() => {
    if (!isFetching && !isLoading && stacks) {
      setDisplayedTools(stacks.sort((a, b) => b.foundationTemplateRefs.length - a.foundationTemplateRefs.length));
    }
  }, [isFetching, isLoading, stacks]);

  if(isFetching || isLoading || !stacks){
    return (
      <div className={styles.loader_wrapper}>
        <Loader size={"large"}  />
        <Text>Loading stacks...</Text>
      </div>
    );
  }

  const handleSearch = (value: string) => {
      let filteredFoundations = stacks?.filter((tool) =>
        tool.name.toLocaleLowerCase().includes(value.toLowerCase())
      )
      setDisplayedTools(filteredFoundations ? filteredFoundations : []);
  };

  const handleSelect = (name: string, values: string[]) => {
    const currentValues = methods.getValues("foundations") || [];
    
    if (selected.includes(name)) {
      // Item is deselected, remove from selected and foundations
      setSelected(selected.filter((item: any) => item !== name));
  
      // Remove the associated values from currentValues
      const updatedValues = currentValues.filter(
        (item: any) => !values.includes(item)
      );
  
      methods.setValue("foundations", updatedValues); // Ensure this is a flat array
      return;
    }
  
    // Item is selected, add to selected and foundations
    setSelected([...selected, name]);
  
    // Add new values to current values (without duplicates)
    const updatedValues = [
      ...currentValues,
      ...values.filter((item: any) => !currentValues.includes(item)), // Fix the condition here to avoid duplicates
    ];
  
    // Set the foundations with the flat array
    methods.setValue("foundations", updatedValues);
    
    // Console logging the result to verify the array structure
  
    return;
  };
  
  if (isLoading || isFetching || !stacks )
    return (
      <div>
        <Text>Loading Stacks...</Text>
      </div>
    );
    if(!Array.isArray(stacks)){
      return (
        <div>
          <Text>You have no stacks...</Text>
        </div>
      );
    }
  return (
    <div className={`flex flex-col gap-4 mt-10`}>
      <div>
        <div className="text-lg text-gray-700">
          Select your stacks
        </div>
        <TextInput
          placeholder="Please select your stack"
          onChange={(e: any) => handleSearch(e.target.value)}
        />
      </div>
      <div className="max-h-96 overflow-auto mb-10">
        <div className="grid grid-cols-2 gap-4">
          {displayedTools.map((item) => 
            <StacksFoundationsCards stack={item} handleSelect={handleSelect} selected={selected.includes(item.name)}/>
          )}
        </div>
      </div>
    </div>
  );
};