import { StatusType } from "types/clusters/clusters";
import * as yup from "yup";

export interface CspAccountsDto {
    name: string;
    phase: StatusType;
    message: string;
    provider: string;
  }
  export interface CspAccountCreationDto {
    accessKey: string;
    secretKey: string;
    name: string;
    projectId?: string;
    provider: string;
  }
  export const cspAccountCreationDSchema = yup.object().shape({
    name: yup.string().required(),
    provider: yup.string().required(),
    accessKey: yup.string().required(),
    secretKey: yup.string().required(),
    projectId: yup.string().when('provider', {
      is: "scaleway",
      then: (schema) => schema.required('Must enter project id for scaleway'),
      otherwise: (schema) => schema.notRequired(),
    }),
  });