import { Dialog, DialogContent } from "components/sredx-ui/Dialog";
import { useModal } from "hooks/useModal";

export const ModalContainer = () => {
  const { isModalOpen, modalContent } = useModal();
  return (
    <Dialog open={isModalOpen}>
      <DialogContent>{modalContent}</DialogContent>
    </Dialog>
  );
};
