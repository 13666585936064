import clsx from "clsx";
// import { LoadingPage } from "pages/loading";
import { useState } from "react";
import { Outlet } from "react-router-dom";
import { AppHeader } from "../AppHeader";
import { AppSideMenu } from "../AppSideMenu";
import styles from "./AppLayout.module.css";
import { PageContent } from "components/sredx-ui/PageContent";
import { LayoutBody, LayoutMain, LayoutSidebar } from "components/sredx-ui/Layout";
import { LayoutSidebarCollapseButton } from "components/sredx-ui/Layout/LayoutSidebarCollapseButton";
// import { useMe } from "services/users/useMe";
import { useAuth } from "auth";
import { Toast } from "components/Toaster/Toaster";


export const AppLayout = () => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const { user } = useAuth();
  const toggleCollapse = () => setIsCollapsed(!isCollapsed);
  const profile = user?.profile;

  if (!profile) {
    return <Toast id={1} message={"Error getting user profile !!"} type={'error'}/>;
  }

  const displayName = profile.given_name && profile.family_name
    ? `${profile.given_name} ${profile.family_name}`
    : profile.preferred_username || profile.email;

  if (typeof displayName !== 'string'  || !displayName) {
    return <Toast id={1} message={"Error formating username !!"} type={'error'}/>;;
  }
    
  return (
    <div className={clsx("nemo-app-layout", styles.wrapper)}>
      <LayoutBody>
        <LayoutSidebar isCollapsed={isCollapsed}>
          <AppSideMenu isCollapsed={isCollapsed} />
        </LayoutSidebar>
        <LayoutSidebarCollapseButton
          isCollapsed={isCollapsed}
          toggleCollapse={toggleCollapse}
        />
        <LayoutMain>
          <PageContent>
            <AppHeader username={displayName} />
          </PageContent>
          <Outlet />
        </LayoutMain>
      </LayoutBody>
    </div>
  );
};
