import { Cluster } from "types/clusters/clusters";
import { ClusterCardComponent } from "components/clusters";
import { useKubeConfig } from "services/kubeconfig/useKubeConfigHook";
import { isKubeConfigDownloadEnabled } from "components/clusters/ClusterCard/utils";

interface ClusterCardContainerProps {
  cluster: Cluster;
}

export const ClusterCardContainer = ({
  cluster,
}: ClusterCardContainerProps) => {
  const { handleDownload, isClusterDownloading } = useKubeConfig();
  const isButtonLoading = isClusterDownloading(cluster.name);
  const isDownloadEnabled = isKubeConfigDownloadEnabled(cluster);
  const handleOnDownload = (clusterName: string) => {
    handleDownload(clusterName);
  };
  return (
    <ClusterCardComponent
      cluster={cluster}
      onDownload={handleOnDownload}
      isButtonLoading={isButtonLoading}
      isDownloadEnabled={isDownloadEnabled}
    />
  );
};
