import { StatusType } from "types/clusters/clusters";
import * as yup from "yup";

export interface GitopsAccountsDto {
    name: string;
    owner: string;
    phase: StatusType;
    message: string;
  }

export type CDTool = "argo" | "flux";

export type GitProvider = "github" | "gitlab" | "bitbucket";


export interface GitopsAccountCreationDto {
    name: string;
    owner: string;
    accessToken: string;
  }
  export const gitopsAccountCreationDSchema = yup.object().shape({
    name: yup.string().required(),
    owner: yup.string().required(),
    accessToken: yup.string().required()
  });