interface ClusterdialiLogoProps {
  className? : string;
  width?: number;
}


const ClusterdialiLogo = ({className, width= 280} : ClusterdialiLogoProps) => {
  return (
      <svg
        width={width}
        height="21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
      >
        <circle cx="14" cy="4" r="4" fill="#FEFCF8" />
        <circle cx="4" cy="14" r="4" fill="#FEFCF8" />
        <rect width="8" height="8" fill="#FEFCF8" />
        <rect x="10" y="10" width="8" height="8" fill="#22D3EE" />
        <text x="22" y="18" style={{fontSize : "24px",  fontWeight: "900", fill: "#FEFCF8" }}>CLUSTERDIALI</text>
      </svg>
  );
}

export default ClusterdialiLogo;