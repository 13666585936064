import { useModal } from "hooks/useModal";
import styles from "./ClusterDetailsDeleteContainer.module.css";
import { useNavigate } from "react-router-dom";
import { CLUSTERS_PAGE } from "routes/paths";
import { ClusterDeleteModal } from "components/clusters/ClusterDetails/ClusterDeleteModal";
import { useDeleteCluster } from "services/clusters/useDeleteCluster";

interface ClusterDetailsDeleteContainerProps {
  id: string;
}
export const ClusterDetailsDeleteContainer = ({
  id,
}: ClusterDetailsDeleteContainerProps) => {
  const { closeModal, openModal  } = useModal();
  const navigate = useNavigate();
  const { mutate: deleteCluster } = useDeleteCluster();

  const handleDeleteCluster = () => {
      deleteCluster(id, {
        onSuccess: () => {
          closeModal();
          navigate(CLUSTERS_PAGE);
        },
        onError: (error: any) => {
          console.error(error)
        },
      });
  };

  const onDeleteCluster = () => {
    openModal({
      content: (<ClusterDeleteModal  closeModal={closeModal} handleDeleteControlplane={handleDeleteCluster}  />),
      options: {
        width: "1000px",
      },
    });
  };

  return (
    <div className={styles.wrapper}>
      <div>
        <h3 className={styles.title}>Delete this cluster</h3>
        <p>
          Deleting your cluster will permanently destroy your cluster and all of
          its associated pools and Instances.
        </p>
      </div>
      <button onClick={onDeleteCluster} className={styles.deleteButton}>
        Delete this cluster
      </button>
    </div>
  );
};
