import { ToastContainer } from "components/Toaster/ToasterContainer";
import "/node_modules/react-grid-layout/css/styles.css";
import "/node_modules/react-resizable/css/styles.css";
import { AuthProvider } from "auth";
import { ModalContainer } from "containers/ModalContainer";
import { AppRoutes } from "routes";

function App() {
  return (
      <AuthProvider >
        <AppRoutes />
        <ModalContainer />
        <ToastContainer />
      </AuthProvider>
  );
}

export default App;
