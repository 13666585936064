export const getColorByPercentage = (percentage: number): string => {
  if (percentage >= 90) {
    return "#ff0000"; // High percentage
  }else if (percentage >= 80) {
    return "#f5a623"; // Average high percentage
  } 
   else if (percentage >= 50) {
    return "orange"; // Average high percentage
  } else {
    return "#00b341"; // Low percentage
  }
};
