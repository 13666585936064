import React from "react";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { QueryClient, QueryClientProvider } from "react-query";
import queryClientConfig from "./config/queryClientConfig";
import { createRoot } from 'react-dom/client';

const queryClient = new QueryClient(queryClientConfig);
const container = document.getElementById('root') as HTMLElement; ;
const root = createRoot(container);

root.render(
<React.StrictMode>
  <QueryClientProvider client={queryClient}>
    <App />
  </QueryClientProvider>
</React.StrictMode>);

// If you want to start measuring performance in your services, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
