import { StacksDto } from "types/stacks/stacks";
import { StackCardComponent } from "components/stacks/StackCardComponent/StackCardComponent";


interface StacksListingCardContainerProps {
  stacks: StacksDto[]
}
export const StacksListingCardContainer = ({stacks}: StacksListingCardContainerProps) => {
  const SortedStacks = stacks.sort((a, b) => b.foundationTemplateRefs.length - a.foundationTemplateRefs.length);

  return (
        <div className="grid grid-cols-3 gap-4 w-full mt-5">
          {SortedStacks.map((stack) =>
            <StackCardComponent key={stack.name} stack={stack} />
          )}
        </div>
  );
};
