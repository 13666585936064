import { CspAccountDeleteModal } from "components/cspAccounts/CspAccountDeleteModal";
import styles from "./CspAccountsListingCardContainer.module.css";
import { useModal } from "hooks/useModal";
import { CspAccountsDto } from "types/csp/csp";
import { CspAccountCard } from "components/cspAccounts/CspAccountCard/CspAccountCard";


interface CspAccountsListingCardContainerProps {
  cspAccounts: CspAccountsDto[]
}
export const CspAccountsListingCardContainer = ({cspAccounts}: CspAccountsListingCardContainerProps) => {
  //hooks
  const { openModal, closeModal } = useModal();

  // handlers
  const handleDeleteCspAccount = (cspAccount: CspAccountsDto) => {
    openModal({
      content: (
        <CspAccountDeleteModal
          cspAccount={cspAccount}
          onClose={closeModal}
        />
      ),
    });
  };
  
  return (
    <div className={styles.cardlist_wrapper}>
      {cspAccounts?.map((cspAccount) => (
        <CspAccountCard
          key={cspAccount.name}
          cspAccount={cspAccount as CspAccountsDto}
          onDelete={handleDeleteCspAccount}
        />
      ))}
    </div>
  );
};
