import { API_ENDPOINTS } from "config/apiPaths";
import { QUERY_KEYS } from "config/queryKeys";
import { ClientApi } from "services/clientApi";
import { useQuery } from "react-query";
import { ManagedResource } from "types/clusters/clusters";

export const useGetClusterManagedResources = (clusterName: string) => {
  return useQuery<ManagedResource[] | undefined, Error>({
    queryKey: [QUERY_KEYS.clusterManagedResources],
    queryFn: async () => await ClientApi.get(API_ENDPOINTS.getClusterManagedResources(clusterName)),
    retry: 5,
    retryDelay: 1000,
    refetchInterval: 2500,
  });
};
