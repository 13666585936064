import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { useToaster } from "hooks/useToaster";
import { LoadingOverlay } from "components/sredx-ui/LoadingOverlay/LoadingOverlay";
import { Button } from "components/sredx-ui/Button";
import { Card, CardBody } from "components/sredx-ui/Card";
import { useCreateStack } from "services/stacks/useCreateStack";
import { useState } from "react";
import { stackCreationDSchema, StackCreationDto } from "types/stacks/stacks";
import { StackCreateForm } from "components/stacks/StackCreateForm/StackCreateForm";
import { StackSelectFoundationsForm } from "components/stacks/StackCreateForm/StackSelectFoundationsForm";


interface StackCreateContainerProps {
  onCancel: () => void;
}
const formSteps = {
  STACK_INFO: "STACK_INFO",
  FOUNDATIONS: "FOUNDATIONS",
};

export const StackCreateContainer = ({
  onCancel,
}: StackCreateContainerProps) => {
  const [formStep, setFormStep] = useState(formSteps.STACK_INFO);
  const { mutate, isLoading } = useCreateStack();
  const { addToast } = useToaster();


  const methods = useForm({
    mode: "onChange",
    resolver: yupResolver(stackCreationDSchema),
  });

  const handleStepToggle = () => {
    if (formStep === formSteps.STACK_INFO) {
      setFormStep(formSteps.FOUNDATIONS);
    }
    if (formStep === formSteps.FOUNDATIONS) {
      setFormStep(formSteps.STACK_INFO);
    }
  };

  const handleClickCreate = () => {
    const stackCreate = (data: StackCreationDto) => {
      mutate(data, {
        onSuccess: () => {
          addToast({
            message: `Stack ${data.name} created successfully.`,
            type: "success",
          });
          onCancel();
        },
        onError: (error: any) => {
          addToast({
            message: `Error occured while creating stack ${data.name}. ${error.message}`,
            type: "error",
          });
        },
      });
    };
    methods.handleSubmit((data) => {
      stackCreate(data)
    })();
    if (!methods.formState.isValid) {
        console.log(methods)
        setFormStep(formSteps.STACK_INFO);
        return;
      }
  };


  const renderFormActions = () => {
    return (
      <div className="flex justify-end gap-4 mt-6">
        {formStep === formSteps.STACK_INFO && (
          <>
            <button onClick={onCancel}>Cancel</button>
            <Button onClick={handleStepToggle}>
              Next
            </Button>
          </>
        )}
        {formStep === formSteps.FOUNDATIONS && (
          <>
            <button className="btn btn-secondary" onClick={handleStepToggle}>
              Back
            </button>
            <Button  onClick={handleClickCreate}>
              Confirm
            </Button>
          </>
        )}
      </div>
    );
  };

  return (
    <Card>
      <CardBody>
        <div
          style={{
            minWidth: "800px",
            padding: "24px 8px",
          }}
        >
          <div className="relative">
            {isLoading && <LoadingOverlay message="Creating Stack" />}
            <h1>Create new stack</h1>
            <FormProvider {...methods}>
              <StackCreateForm
                errors={methods.formState.errors}
                display={formStep === formSteps.STACK_INFO}
              />
              <StackSelectFoundationsForm display={formStep === formSteps.FOUNDATIONS} />
            </FormProvider>
            {renderFormActions()}
            </div>
        </div>
      </CardBody>
    </Card>
  );
};
