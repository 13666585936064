import { FoundationTemplatesDatatable } from "components/foundationTemplates/FoundationTemplatesDatatable/FoundationTemplatesDatatable";
import styles from "./FoundationTemplatesListingDataTableContainer.module.css";
import { FoundationTemplatesDto } from "types/foundationtemplates/foundationtemplates";
import { useModal } from "hooks/useModal";
import { FoundationTemplateDeleteModal } from "components/foundationTemplates/FoundationTemplatesDeleteModal";

interface FoundationTemplatesListingCardContainerProps {
  foundationTemplates: FoundationTemplatesDto[]
}

export const FoundationTemplatesListingDataTableContainer = ({foundationTemplates}: FoundationTemplatesListingCardContainerProps) => {

  //handlers
  const handleClickService = (serviceId: string) => console.log(serviceId);
  const { openModal, closeModal } = useModal();

  const handleDeleteFoundationTemplate = (foundationTemplate: FoundationTemplatesDto) => {
    openModal({
      content: (
        <FoundationTemplateDeleteModal
          foundationTemplate={foundationTemplate}
          onClose={closeModal}
        />
      ),
    });
  };

  return (
    <div className={styles.datatable_wrapper}>
      <FoundationTemplatesDatatable
        foundationTemplates={foundationTemplates}
        onClickService={handleClickService}
        onDelete={handleDeleteFoundationTemplate}
      />
    </div>
  );
};
