import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { useToaster } from "hooks/useToaster";
import { LoadingOverlay } from "components/sredx-ui/LoadingOverlay/LoadingOverlay";
import { Button } from "components/sredx-ui/Button";
import { FoundationTemplateCreateForm } from "components/foundationTemplates/FoundationTemplatesCreateForm/FoundationTemplatesCreateForm";
import { useCreateFoundationTemplate } from "services/foundationtemplates/useCreateFoundationTemplate";
import { FoundationTemplatesCreationDto, foundationTemplateCreationDSchema } from "types/foundationtemplates/foundationtemplates";
import { Card, CardBody } from "components/sredx-ui/Card";


interface FoundationTemplateCreateContainerProps {
  onCancel: () => void;
}
export const FoundationTemplateCreateContainer = ({
  onCancel,
}: FoundationTemplateCreateContainerProps) => {
  const { mutate, isLoading } = useCreateFoundationTemplate();
  const { addToast } = useToaster();


  const methods = useForm({
    mode: "onChange",
    resolver: yupResolver(foundationTemplateCreationDSchema),
  });

  const handleClickCreate = () => {
    const foundationAccountCreate = (data: FoundationTemplatesCreationDto) => {
      mutate(data, {
        onSuccess: () => {
          addToast({
            message: `Foundation template ${data.name} created successfully.`,
            type: "success",
          });
          onCancel();
        },
        onError: (error: any) => {
          addToast({
            message: `Error occured while creating foundation template ${data.name}. ${error.message}`,
            type: "error",
          });
        },
      });
    };
    methods.handleSubmit((data) => foundationAccountCreate(data))();
  };

  return (
    <Card>
      <CardBody>
        <div
          style={{
            minWidth: "800px",
            padding: "24px 8px",
          }}
        >
          <div className="relative">
            {isLoading && <LoadingOverlay message="Creating Foundation Template" />}
            <h1>Create new Foundation Template</h1>
            <FormProvider {...methods}>
              <FoundationTemplateCreateForm
                errors={methods.formState.errors}
              />
            </FormProvider>
            <div className="flex justify-end gap-4 mt-6">
              <Button onClick={handleClickCreate}>
                Confirm
              </Button>
              <Button variant="neutral" onClick={onCancel}>
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};
