import { API_ENDPOINTS } from "config/apiPaths";
import { QUERY_KEYS } from "config/queryKeys";
import { ClientApi } from "services/clientApi";
import { useQuery } from "react-query";
import { Cluster } from "types/clusters/clusters";

export const useGetClusterDetailInfo = (clusterName: string | undefined) => {
  return useQuery<Cluster>({
    queryKey: [QUERY_KEYS.clusterDetails+clusterName],
    queryFn: async () => await ClientApi.get(API_ENDPOINTS.getClusterDetails(clusterName)),
    refetchInterval: 2500,
    retry: 5,
    retryDelay: 1000,
  });
};
