import styles from "./ClusterCreatePage.module.css";
import { PageContent } from "components/sredx-ui/PageContent";
import { Heading } from "components/sredx-ui/Text";
import { ClusterCreatePageContainer } from "containers/clusters/ClusterCreatePageContainer/ClusterCreatePageContainer";


const ClusterCreatePage = () => {

  return (
    <PageContent>
      <div className={styles.wrapper}>
        <div className="text-center mt-10">
          <div>
            <Heading>Create your cluster</Heading>
            <p>
              Quickly set up a cluster and streamline your workflow with effective day-2 operations.
            </p>
          </div>
        </div>
        <ClusterCreatePageContainer />
      </div>
    </PageContent>
  );
};

export default ClusterCreatePage;
